import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import styles from "./styles/user_management.module.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Button, SubTitle } from "../../Components/CustomComponents";
import { IoCaretForwardSharp, IoCaretBackSharp } from "react-icons/io5";
import { FaEye, FaBackward, FaForward, FaRegEdit, FaEdit, FaTrash } from "react-icons/fa";
import Table from "../../Components/Table/Table";
import { Col, Row, Stack } from "react-bootstrap";
import Toast from "../../Hooks/useToast";
import { getUsers, updateUser, deleteUser } from "../../services/UserService";
import EditUserModal from "../../Components/Modals/EditUserModal";

const UserManagementScreen = ({ setPage, setSlug }) => {
  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [paginationDetail, setPaginationDetail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [search, setSearch] = useState("");
  const [userPage, setUserPage] = useState(1);

  const GetAllUsers = useCallback(async () => {
    if (loader) return;
    setLoader(true);
    try {
      let params = {
        page: userPage,
        limit: 10,
        search: search,
      };
      const result = await getUsers(params);
      setData(result?.data?.data);
      setPaginationDetail(result?.data?.links);
      setIndicationMessage("");
    }
    catch (e) {
      Toast(e?.response?.message || "Error loading teams", "error");
    }
    finally {
      setLoader(false);
    }
  }, [userPage, search]);

  const debouncedGetUserList = useCallback(
      debounce(() => {
        GetAllUsers();
      }, 1000),
      [GetAllUsers]
    );
    
  useEffect(() => {
    debouncedGetUserList();
    return debouncedGetUserList.cancel;
  }, [search, debouncedGetUserList]);

  const handleAddUser = () => {
    setSlug(null);
    setPage("CREATE_USER");
  };

  const handleViewUser = (data) => {
    setEditModal(true);
    setRowData(data);
  };

  const handleDeleteUser = async (data) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const res = await deleteUser(data.slug);

      if (res.status === 200) {
        Toast(res?.data?.message, "success");
        debouncedGetUserList();
        setEditModal(false);
      } else {
        console.log('Error editing Tournament');
      }
    }
  };

  const handleEditModalClose = () => {
    setEditModal(false)
  };

  function indication() {
    return indicationMessage;
  }

  const EditUser = async (userData) => {
    if (userData !== null || userData !== undefined) {

      const res = await updateUser(rowData.slug, userData);

      if (res.status === 200) {
          Toast(res?.data?.message, "success");
          debouncedGetUserList();
          setEditModal(false);
      } else {
        console.log('Error editing Tournament');
      }
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => (
        row.name && (
          <div className="d-flex align-items-center">
            <img
              src={`//www.primetime-tournaments.com/${row?.image_url}`}
              alt="profile"
              style={{
                height: "40px",
                maxHeight: "50px",
                borderRadius: "50%",
                margin: "10px",
                padding: "5px",
              }}
            />
            <span>{row?.name}</span>
          </div>
        )
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "300px",
      },
    },
    {
      dataField: "role",
      text: "Roles",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => (
        row.user_type && (
          <div className="d-flex justify-content-center align-items-center">
            <span>{row.user_type}</span>
          </div>
        )
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.slug && (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 p-2 mx-2`}
              role="button"
              onClick={() => handleViewUser(row)}
            >
              <span className="d-flex align-items-center pe-1">
                <FaEdit />
              </span>
              <span>Edit</span>
            </div>
            <div
              className={`d-flex justify-content-center align-items-center btn btn-danger rounded-3 p-2 mx-2`}
              role="button"
              onClick={() => handleDeleteUser(row)}
            >
              <span className="d-flex align-items-center pe-1">
                <FaTrash />
              </span>
              <span>Delete</span>
            </div>
          </div>
        )
      ),
    },
  ];

  const handleBackPagination = () => {
    if (paginationDetail?.current > 1) {
      setUserPage((prev) => prev - 1);
    }
  };
  
  const handleForwardPagination = () => {
    if (paginationDetail?.current < paginationDetail?.total) {
      setUserPage((prev) => prev + 1);
    }
  };

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"User Management"} classes="text-dark" />
        </div>
        <Row>
          <Col md={12}>
            <button
              className={styles.cardBtn}
              onClick={handleAddUser}
            >Add User</button>
          </Col>
        </Row>
      </div>
      <div className={styles.searchContainer}>
        <Row>
          <Col>
            <Stack direction="horizontal" gap={3}>
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search for User..."
                className={`${styles.searchInput} form-control`}
              />
            </Stack>
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table data={data} columns={columns} indication={indication} />
        <div className="pagination" style={{ fontSize: "15px", color: "black", display: "flex", justifyContent: "end", alignItems: "center", marginRight: "12px" }}>
          <FaBackward
            cursor="pointer"
            color={paginationDetail?.current === 1 ? "gray" : "blue"}
            size={20}
            onClick={() => setUserPage(1)}
          />
          <IoCaretBackSharp
            cursor="pointer"
            color={paginationDetail?.current === 1 ? "gray" : "blue"}
            size={20}
            onClick={handleBackPagination}
          />
          <span>{paginationDetail?.current}/{paginationDetail?.total}</span>
          <IoCaretForwardSharp
            cursor="pointer"
            color={paginationDetail?.current === paginationDetail?.total ? "gray" : "blue"}
            size={20}
            onClick={handleForwardPagination}
          />
          <FaForward
            cursor="pointer"
            color={paginationDetail?.current === paginationDetail?.total ? "gray" : "blue"}
            size={20}
            onClick={() => setUserPage(paginationDetail?.total)}
          />
        </div>
      </div>

    {editModal ? (
      <EditUserModal
          show={editModal}
          handleEditModalClose={handleEditModalClose}
          onEdit={EditUser}
          data={rowData}
          edittxt={"Save Changes"}
      />
    ) : (
      ""
    )}
    </div>
  );
};

export default UserManagementScreen;
