import React from 'react';
// import styles from './TreeComponent2.css';

const BracketTable = () => {

  return (
    <div className="bracket">
      <svg width="1700" height="1500" xmlns="http://www.w3.org/2000/svg">
        <text x="20" y="50" font-size="16" font-weight="bold">Winner's Bracket</text>

        <rect x="200" y="335" width="120" height="30" fill="lightblue" stroke="black" />
        <text x="210" y="355" font-size="12">Team 3</text>
        <rect x="200" y="500" width="120" height="30" fill="lightblue" stroke="black" />
        <text x="210" y="520" font-size="12">Team 4</text>
        <line x1="320" y1="350" x2="350" y2="350" stroke="black" />
        <line x1="320" y1="515" x2="350" y2="515" stroke="black" />
        <line x1="350" y1="350" x2="350" y2="515" stroke="black" />
        {/* end of round w4 */}

        <rect x="350" y="85" width="120" height="30" fill="lightblue" stroke="black" />
        <text x="360" y="105" font-size="12">Team 1</text>
        <rect x="350" y="415" width="120" height="30" fill="lightblue" stroke="black" />
        <text x="360" y="435" font-size="12">Team 2</text>
        <line x1="470" y1="90" x2="650" y2="90" stroke="black" />
        <line x1="470" y1="430" x2="650" y2="430" stroke="black" />
        <line x1="650" y1="90" x2="650" y2="430" stroke="black" />
        {/* end of round w5 */}

        <rect x="650" y="260" width="120" height="30" fill="lightblue" stroke="black" />
        <text x="670" y="280" font-size="12">Team 1</text>

        <text x="500" y="600" font-size="16" font-weight="bold">Loser's Bracket</text>

        <rect x="500" y="650" width="120" height="30" fill="lightyellow" stroke="black" />
        <text x="520" y="670" font-size="12">Loser 1</text>
        <rect x="500" y="930" width="120" height="30" fill="lightyellow" stroke="black" />
        <text x="520" y="950" font-size="12">Loser 2</text>
        <line x1="620" y1="660" x2="650" y2="660" stroke="black" />
        <line x1="620" y1="945" x2="650" y2="945" stroke="black" />
        <line x1="650" y1="660" x2="650" y2="945" stroke="black" />
        {/* end of round l6 */}

        <rect x="650" y="605" width="120" height="30" fill="lightyellow" stroke="black" />
        <text x="670" y="625" font-size="12">Loser 1</text>
        <rect x="650" y="740" width="120" height="30" fill="lightyellow" stroke="black" />
        <text x="670" y="760" font-size="12">Loser 2</text>
        <line x1="770" y1="620" x2="800" y2="620" stroke="black" />
        <line x1="770" y1="755" x2="800" y2="755" stroke="black" />
        <line x1="800" y1="620" x2="800" y2="755" stroke="black" />
        {/* end of round l7 */}

        <rect x="800" y="670" width="120" height="30" fill="lightyellow" stroke="black" />
        <text x="820" y="690" font-size="12">Loser 1</text>
        {/* end of round l8 */}

        <line x1="770" y1="275" x2="1000" y2="275" stroke="black" />
        <line x1="920" y1="685" x2="1000" y2="685" stroke="black" />
        <line x1="1000" y1="275" x2="1000" y2="685" stroke="black" />

        <rect x="1000" y="490" width="120" height="30" fill="lightyellow" stroke="black" />
        <text x="1020" y="510" font-size="12">winner 1</text>
        <rect x="1000" y="770" width="120" height="30" fill="lightyellow" stroke="black" />
        <text x="1020" y="790" font-size="12">loser 2</text>
        <line x1="1120" y1="505" x2="1150" y2="505" stroke="black" />
        <line x1="1120" y1="785" x2="1150" y2="785" stroke="black" />
        <line x1="1150" y1="505" x2="1150" y2="785" stroke="black" />
        {/* end of round l9 */}

        <rect x="1150" y="620" width="120" height="30" fill="lightyellow" stroke="black" />
        <text x="1170" y="640" font-size="12">winner 1</text>
        {/* end of round l10 */}
      </svg>

    </div>
  );
};

export default BracketTable;