import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "./table.css";

const Table = ({ data, columns, indication }) => {
  // PAGINATION STYLES
  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "#0D0D1C";
      activeStyle.color = "#FFF";
      activeStyle.border = "5px solid #0AA7FF";
      activeStyle.padding = "15px  30px";
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";
      activeStyle.fontSize = "larger";
    } else {
      // activeStyle.backgroundColor = '#21253e';
      activeStyle.backgroundColor = "#0D0D1C";
      activeStyle.color = "#fff";
      // activeStyle.border = '2px solid #0AA7FF';
      activeStyle.padding = "15px";
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "#0D0D1C";
      activeStyle.color = "#fff";
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";
      activeStyle.border = "2px solid #0D0D1C";
    }
    return (
      <li className="page-item" key={page} style={{ padding: "5px" }}>
        <a href="#" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };
  // pagination options
  const paginationOptions = {
    pageButtonRenderer,
  };
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
    },
  };
  return (
    <BootstrapTable
      bootstrap4
      keyField="id"
      data={data}
      key={data.slug}
      columns={columns}
      bordered={false}
      noDataIndication={indication}
      // pagination={paginationFactory(paginationOptions)}
      rowEvents={rowEvents}
    />
  );
};

export default Table;
