import React from 'react';
// import styles from './TreeComponent2.css';

const BracketTable = () => {

  return (
    <div className="bracket">
      <svg width="2000" height="1600" xmlns="http://www.w3.org/2000/svg">
            <text x="20" y="30" font-size="16" font-weight="bold">Winner's Bracket</text>
        
            <rect x="65" y="155" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="75" y="175" font-size="12">Team 3</text>
            <rect x="65" y="235" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="75" y="255" font-size="12">Team 4</text>
            <line x1="185" y1="170" x2="215" y2="170" stroke="black" />
            <line x1="185" y1="250" x2="215" y2="250" stroke="black" />
            <line x1="215" y1="170" x2="215" y2="250" stroke="black" />
        
            <rect x="65" y="315" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="75" y="335" font-size="12">Team 5</text>
            <rect x="65" y="395" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="75" y="415" font-size="12">Team 6</text>
            <line x1="185" y1="330" x2="215" y2="330" stroke="black" />
            <line x1="185" y1="410" x2="215" y2="410" stroke="black" />
            <line x1="215" y1="330" x2="215" y2="410" stroke="black" />
            {/* end of round w3 */}
        
            <rect x="215" y="30" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="225" y="50" font-size="12">Team 1</text>
            <rect x="215" y="195" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="225" y="215" font-size="12">Team 2</text>
            <line x1="335" y1="45" x2="365" y2="45" stroke="black" />
            <line x1="335" y1="210" x2="365" y2="210" stroke="black" />
            <line x1="365" y1="45" x2="365" y2="210" stroke="black" />
        
            <rect x="215" y="350" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="225" y="370" font-size="12">Team 3</text>
            <rect x="215" y="515" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="225" y="535" font-size="12">Team 4</text>
            <line x1="335" y1="365" x2="365" y2="365" stroke="black" />
            <line x1="335" y1="530" x2="365" y2="530" stroke="black" />
            <line x1="365" y1="365" x2="365" y2="530" stroke="black" />
            {/* end of round w4 */}
        
            <rect x="365" y="100" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="375" y="120" font-size="12">Team 1</text>
            <rect x="365" y="430" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="375" y="450" font-size="12">Team 2</text>
            <line x1="485" y1="105" x2="665" y2="105" stroke="black" />
            <line x1="485" y1="445" x2="665" y2="445" stroke="black" />
            <line x1="665" y1="105" x2="665" y2="445" stroke="black" />
            {/* end of round w5 */}
        
            <rect x="665" y="275" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="685" y="295" font-size="12">Team 1</text>
        
            <text x="300" y="615" font-size="16" font-weight="bold">Loser's Bracket</text>
        
            <rect x="365" y="635" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="375" y="655" font-size="12">Loser 1</text>
            <rect x="365" y="710" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="375" y="730" font-size="12">Loser 2</text>
            <line x1="485" y1="650" x2="515" y2="650" stroke="black" />
            <line x1="485" y1="725" x2="515" y2="725" stroke="black" />
            <line x1="515" y1="650" x2="515" y2="725" stroke="black" />
        
            <rect x="365" y="910" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="375" y="930" font-size="12">Loser 3</text>
            <rect x="365" y="985" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="375" y="1005" font-size="12">Loser 4</text>
            <line x1="485" y1="925" x2="515" y2="925" stroke="black" />
            <line x1="485" y1="1000" x2="515" y2="1000" stroke="black" />
            <line x1="515" y1="925" x2="515" y2="1000" stroke="black" />
            {/* end of round l5 */}
        
            <rect x="515" y="665" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="535" y="685" font-size="12">Loser 1</text>
            <rect x="515" y="945" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="535" y="965" font-size="12">Loser 2</text>
            <line x1="635" y1="675" x2="665" y2="675" stroke="black" />
            <line x1="635" y1="960" x2="665" y2="960" stroke="black" />
            <line x1="665" y1="675" x2="665" y2="960" stroke="black" />
            {/* end of round l6 */}
        
            <rect x="665" y="620" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="685" y="640" font-size="12">Loser 1</text>
            <rect x="665" y="755" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="685" y="775" font-size="12">Loser 2</text>
            <line x1="785" y1="635" x2="815" y2="635" stroke="black" />
            <line x1="785" y1="770" x2="815" y2="770" stroke="black" />
            <line x1="815" y1="635" x2="815" y2="770" stroke="black" />
            {/* end of round l7 */}
        
            <rect x="815" y="685" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="835" y="705" font-size="12">Loser 1</text>
            {/* end of round l8 */}
        
            <line x1="785" y1="290" x2="1015" y2="290" stroke="black" />
            <line x1="935" y1="700" x2="1015" y2="700" stroke="black" />
            <line x1="1015" y1="290" x2="1015" y2="700" stroke="black" />
            
            <rect x="1015" y="505" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1035" y="525" font-size="12">winner 1</text>
            <rect x="1015" y="785" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1035" y="805" font-size="12">loser 2</text>
            <line x1="1135" y1="520" x2="1165" y2="520" stroke="black" />
            <line x1="1135" y1="805" x2="1165" y2="805" stroke="black" />
            <line x1="1165" y1="520" x2="1165" y2="805" stroke="black" />
            {/* end of round l9 */}
        
            <rect x="1165" y="635" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1185" y="655" font-size="12">winner 1</text>
            {/* end of round l10 */}
        </svg>
    </div>
  );
};

export default BracketTable;