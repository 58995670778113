import React from 'react';
// import styles from './TreeComponent2.css';

const BracketTable = () => {

  return (
    <div className="bracket">
        <svg width="2000" height="1600" xmlns="http://www.w3.org/2000/svg">
            <text x="20" y="30" font-size="16" font-weight="bold">Winner's Bracket</text>

            <rect x="50" y="50" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="60" y="70" font-size="12">Team 1</text>
            <rect x="50" y="90" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="60" y="110" font-size="12">Team 2</text>
            <line x1="170" y1="65" x2="200" y2="65" stroke="black" />
            <line x1="170" y1="100" x2="200" y2="100" stroke="black" />
            <line x1="200" y1="65" x2="200" y2="100" stroke="black" />
            {/* end of round w1 */}

            <rect x="200" y="65" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="85" font-size="12">Team 1</text>
            <rect x="200" y="105" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="125" font-size="12">Team 2</text>
            <line x1="320" y1="80" x2="350" y2="80" stroke="black" />
            <line x1="320" y1="115" x2="350" y2="115" stroke="black" />
            <line x1="350" y1="80" x2="350" y2="115" stroke="black" />

            <rect x="200" y="145" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="165" font-size="12">Team 3</text>
            <rect x="200" y="185" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="205" font-size="12">Team 4</text>
            <line x1="320" y1="160" x2="350" y2="160" stroke="black" />
            <line x1="320" y1="195" x2="350" y2="195" stroke="black" />
            <line x1="350" y1="160" x2="350" y2="195" stroke="black" />

            <rect x="200" y="225" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="245" font-size="12">Team 5</text>
            <rect x="200" y="265" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="285" font-size="12">Team 6</text>
            <line x1="320" y1="240" x2="350" y2="240" stroke="black" />
            <line x1="320" y1="275" x2="350" y2="275" stroke="black" />
            <line x1="350" y1="240" x2="350" y2="275" stroke="black" />

            <rect x="200" y="305" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="325" font-size="12">Team 7</text>
            <rect x="200" y="345" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="365" font-size="12">Team 8</text>
            <line x1="320" y1="320" x2="350" y2="320" stroke="black" />
            <line x1="320" y1="355" x2="350" y2="355" stroke="black" />
            <line x1="350" y1="320" x2="350" y2="355" stroke="black" />
            
            <rect x="200" y="385" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="405" font-size="12">Team 9</text>
            <rect x="200" y="425" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="445" font-size="12">Team 10</text>
            <line x1="320" y1="400" x2="350" y2="400" stroke="black" />
            <line x1="320" y1="435" x2="350" y2="435" stroke="black" />
            <line x1="350" y1="400" x2="350" y2="435" stroke="black" />

            <rect x="200" y="465" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="485" font-size="12">Team 11</text>
            <rect x="200" y="505" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="525" font-size="12">Team 12</text>
            <line x1="320" y1="480" x2="350" y2="480" stroke="black" />
            <line x1="320" y1="515" x2="350" y2="515" stroke="black" />
            <line x1="350" y1="480" x2="350" y2="515" stroke="black" />

            <rect x="200" y="545" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="565" font-size="12">Team 13</text>
            <rect x="200" y="585" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="605" font-size="12">Team 14</text>
            <line x1="320" y1="560" x2="350" y2="560" stroke="black" />
            <line x1="320" y1="595" x2="350" y2="595" stroke="black" />
            <line x1="350" y1="560" x2="350" y2="595" stroke="black" />

            <rect x="200" y="625" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="645" font-size="12">Team 15</text>
            <rect x="200" y="665" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="210" y="685" font-size="12">Team 16</text>
            <line x1="320" y1="640" x2="350" y2="640" stroke="black" />
            <line x1="320" y1="675" x2="350" y2="675" stroke="black" />
            <line x1="350" y1="640" x2="350" y2="675" stroke="black" />
            {/* end of round w2 */}

            <rect x="350" y="80" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="360" y="100" font-size="12">Team 1</text>
            <rect x="350" y="160" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="360" y="180" font-size="12">Team 2</text>
            <line x1="470" y1="95" x2="500" y2="95" stroke="black" />
            <line x1="470" y1="175" x2="500" y2="175" stroke="black" />
            <line x1="500" y1="95" x2="500" y2="175" stroke="black" />

            <rect x="350" y="240" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="360" y="260" font-size="12">Team 3</text>
            <rect x="350" y="320" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="360" y="340" font-size="12">Team 4</text>
            <line x1="470" y1="255" x2="500" y2="255" stroke="black" />
            <line x1="470" y1="335" x2="500" y2="335" stroke="black" />
            <line x1="500" y1="255" x2="500" y2="335" stroke="black" />

            <rect x="350" y="400" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="360" y="420" font-size="12">Team 5</text>
            <rect x="350" y="480" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="360" y="500" font-size="12">Team 6</text>
            <line x1="470" y1="415" x2="500" y2="415" stroke="black" />
            <line x1="470" y1="495" x2="500" y2="495" stroke="black" />
            <line x1="500" y1="415" x2="500" y2="495" stroke="black" />

            <rect x="350" y="560" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="360" y="580" font-size="12">Team 7</text>
            <rect x="350" y="640" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="360" y="660" font-size="12">Team 8</text>
            <line x1="470" y1="575" x2="500" y2="575" stroke="black" />
            <line x1="470" y1="655" x2="500" y2="655" stroke="black" />
            <line x1="500" y1="575" x2="500" y2="655" stroke="black" />
            {/* end of round w3 */}

            <rect x="500" y="115" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="510" y="135" font-size="12">Team 1</text>
            <rect x="500" y="280" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="510" y="300" font-size="12">Team 2</text>
            <line x1="620" y1="130" x2="650" y2="130" stroke="black" />
            <line x1="620" y1="295" x2="650" y2="295" stroke="black" />
            <line x1="650" y1="130" x2="650" y2="295" stroke="black" />

            <rect x="500" y="435" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="510" y="455" font-size="12">Team 3</text>
            <rect x="500" y="600" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="510" y="620" font-size="12">Team 4</text>
            <line x1="620" y1="450" x2="650" y2="450" stroke="black" />
            <line x1="620" y1="615" x2="650" y2="615" stroke="black" />
            <line x1="650" y1="450" x2="650" y2="615" stroke="black" />
            {/* end of round w4 */}

            <rect x="650" y="185" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="660" y="205" font-size="12">Team 1</text>
            <rect x="650" y="515" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="660" y="535" font-size="12">Team 2</text>
            <line x1="770" y1="190" x2="950" y2="190" stroke="black" />
            <line x1="770" y1="530" x2="950" y2="530" stroke="black" />
            <line x1="950" y1="190" x2="950" y2="530" stroke="black" />
            {/* end of round w5 */}

            //this round winner goes with loser round 7
            <rect x="950" y="360" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="970" y="380" font-size="12">Team 1</text>



            <text x="20" y="700" font-size="16" font-weight="bold">Loser's Bracket</text>

            <rect x="50" y="720" width="120" height="30" fill="pink" stroke="black" />
            <text x="60" y="740" font-size="12">Loser 1</text>
            <rect x="50" y="770" width="120" height="30" fill="pink" stroke="black" />
            <text x="60" y="790" font-size="12">Loser 2</text>
            <line x1="170" y1="735" x2="200" y2="735" stroke="black" />
            <line x1="170" y1="785" x2="200" y2="785" stroke="black" />
            <line x1="200" y1="735" x2="200" y2="785" stroke="black" />
            {/* end of round l1 */}
            
            <rect x="200" y="745" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="210" y="765" font-size="12">Loser 1</text>
            <rect x="200" y="795" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="210" y="815" font-size="12">Loser 2</text>
            <line x1="320" y1="760" x2="350" y2="760" stroke="black" />
            <line x1="320" y1="805" x2="350" y2="805" stroke="black" />
            <line x1="350" y1="760" x2="350" y2="805" stroke="black" />

            <rect x="200" y="845" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="210" y="865" font-size="12">Loser 3</text>
            <rect x="200" y="895" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="210" y="915" font-size="12">Loser 4</text>
            <line x1="320" y1="860" x2="350" y2="860" stroke="black" />
            <line x1="320" y1="905" x2="350" y2="905" stroke="black" />
            <line x1="350" y1="860" x2="350" y2="905" stroke="black" />

            <rect x="200" y="945" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="210" y="965" font-size="12">Loser 5</text>
            <rect x="200" y="995" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="210" y="1015" font-size="12">Loser 6</text>
            <line x1="320" y1="960" x2="350" y2="960" stroke="black" />
            <line x1="320" y1="1005" x2="350" y2="1005" stroke="black" />
            <line x1="350" y1="960" x2="350" y2="1005" stroke="black" />

            <rect x="200" y="1045" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="210" y="1065" font-size="12">Loser 7</text>
            <rect x="200" y="1095" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="210" y="1115" font-size="12">Loser 8</text>
            <line x1="320" y1="1060" x2="350" y2="1060" stroke="black" />
            <line x1="320" y1="1105" x2="350" y2="1105" stroke="black" />
            <line x1="350" y1="1060" x2="350" y2="1105" stroke="black" />
            {/* end of round l2 */}

            <rect x="350" y="720" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="360" y="740" font-size="12">Loser 1</text>
            <rect x="350" y="770" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="360" y="790" font-size="12">Loser 2</text>
            <line x1="470" y1="740" x2="500" y2="740" stroke="black" />
            <line x1="470" y1="785" x2="500" y2="785" stroke="black" />
            <line x1="500" y1="740" x2="500" y2="785" stroke="black" />

            <rect x="350" y="820" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="360" y="840" font-size="12">Loser 3</text>
            <rect x="350" y="870" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="360" y="890" font-size="12">Loser 4</text>
            <line x1="470" y1="840" x2="500" y2="840" stroke="black" />
            <line x1="470" y1="885" x2="500" y2="885" stroke="black" />
            <line x1="500" y1="840" x2="500" y2="885" stroke="black" />

            <rect x="350" y="920" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="360" y="940" font-size="12">Loser 5</text>
            <rect x="350" y="970" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="360" y="990" font-size="12">Loser 6</text>
            <line x1="470" y1="940" x2="500" y2="940" stroke="black" />
            <line x1="470" y1="985" x2="500" y2="985" stroke="black" />
            <line x1="500" y1="940" x2="500" y2="985" stroke="black" />

            <rect x="350" y="1020" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="360" y="1040" font-size="12">Loser 7</text>
            <rect x="350" y="1070" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="360" y="1090" font-size="12">Loser 8</text>
            <line x1="470" y1="1040" x2="500" y2="1040" stroke="black" />
            <line x1="470" y1="1085" x2="500" y2="1085" stroke="black" />
            <line x1="500" y1="1040" x2="500" y2="1085" stroke="black" />
            {/* end of round l3 */}

            <rect x="500" y="750" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="510" y="770" font-size="12">Loser 1</text>
            <rect x="500" y="850" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="510" y="870" font-size="12">Loser 2</text>
            <line x1="620" y1="765" x2="650" y2="765" stroke="black" />
            <line x1="620" y1="865" x2="650" y2="865" stroke="black" />
            <line x1="650" y1="765" x2="650" y2="865" stroke="black" />

            <rect x="500" y="950" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="510" y="970" font-size="12">Loser 3</text>
            <rect x="500" y="1050" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="510" y="1070" font-size="12">Loser 4</text>
            <line x1="620" y1="965" x2="650" y2="965" stroke="black" />
            <line x1="620" y1="1065" x2="650" y2="1065" stroke="black" />
            <line x1="650" y1="965" x2="650" y2="1065" stroke="black" />
            {/* end of round l4 */}
            <rect x="650" y="720" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="660" y="740" font-size="12">Loser 1</text>
            <rect x="650" y="795" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="660" y="815" font-size="12">Loser 2</text>
            <line x1="770" y1="735" x2="800" y2="735" stroke="black" />
            <line x1="770" y1="810" x2="800" y2="810" stroke="black" />
            <line x1="800" y1="735" x2="800" y2="810" stroke="black" />

            <rect x="650" y="995" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="660" y="1015" font-size="12">Loser 3</text>
            <rect x="650" y="1070" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="660" y="1090" font-size="12">Loser 4</text>
            <line x1="770" y1="1010" x2="800" y2="1010" stroke="black" />
            <line x1="770" y1="1085" x2="800" y2="1085" stroke="black" />
            <line x1="800" y1="1010" x2="800" y2="1085" stroke="black" />
            {/* end of round l5 */}

            <rect x="800" y="750" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="820" y="770" font-size="12">Loser 1</text>
            <rect x="800" y="1030" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="820" y="1050" font-size="12">Loser 2</text>
            <line x1="920" y1="760" x2="950" y2="760" stroke="black" />
            <line x1="920" y1="1045" x2="950" y2="1045" stroke="black" />
            <line x1="950" y1="760" x2="950" y2="1045" stroke="black" />
            {/* end of round l6 */}

            <rect x="950" y="705" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="970" y="725" font-size="12">Loser 1</text>
            <rect x="950" y="840" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="970" y="860" font-size="12">Loser 2</text>
            <line x1="1070" y1="720" x2="1100" y2="720" stroke="black" />
            <line x1="1070" y1="855" x2="1100" y2="855" stroke="black" />
            <line x1="1100" y1="720" x2="1100" y2="855" stroke="black" />
            {/* end of round l7 */}

            <rect x="1100" y="770" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1120" y="790" font-size="12">Loser 1</text>
            {/* end of round l8 */}

            <line x1="1070" y1="375" x2="1300" y2="375" stroke="black" />
            <line x1="1220" y1="785" x2="1300" y2="785" stroke="black" />
            <line x1="1300" y1="375" x2="1300" y2="785" stroke="black" />
            
            <rect x="1300" y="590" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1320" y="610" font-size="12">winner 1</text>
            <rect x="1300" y="870" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1320" y="890" font-size="12">loser 2</text>
            <line x1="1420" y1="605" x2="1450" y2="605" stroke="black" />
            <line x1="1420" y1="885" x2="1450" y2="885" stroke="black" />
            <line x1="1450" y1="605" x2="1450" y2="885" stroke="black" />
            {/* end of round l9 */}

            <rect x="1450" y="720" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1470" y="740" font-size="12">winner 1</text>
            {/* end of round l10 */}


            </svg>
    </div>
  );
};

export default BracketTable;