export const BASE_URL_V1 = process.env.REACT_APP_API_DOMAIN;

export const WEB_ROUTES = {
  ACCOUNT: "/account",
  DASHBOARD: "/dashboard",
  TOURNAMENT_MANAGEMENT: "/tournament-management",
  COACH_MANAGEMENT: "/coach-management",
  PLAYER_MANAGEMENT: "/player-management",
  TEAM_MANAGEMENT: "/team-management",
  TEAM_DETAIL_MANAGEMENT: "/team-detail-management",
  USER_MANAGEMENT: "/user-management",
  SETTING: "/settings",
};

export const LOCAL_STORAGE = {
  setUser: "PTTUSER",
  setToken: "PTTTOKEN",
};

export const BREAKPOINTS = {
  MOBILE: 991,
};
export const apiUrl = {
  team: "team",
  coach: "coach",
  member: "member",
  auth: "auth",
  api: "api",
  role: "admin",
  user: "user",
  login: "login",
  forgotPass: "forgot-password",
  changePass: "change-password",
  // tournament
  tournament: "tournament",
  livetournament: "tournament/live",
  scheduletournament: "tournament/schedule",
  ageGroup: "age-group",
  refund: "refund",
  startmatch: "match/start",
  endmatch: "match/end",
  player: "player",
  unregister: "unregister",
  allUsers: "all-users",
  users: "users",
};

// DATA ->
// DUMMY
export const sportsStateList = [
  { value: 1, label: "State 1" },
  { value: 2, label: "State 2" },
  { value: 3, label: "State 3" },
];

export const sportsTypeList = [
  { value: "Baseball", label: "Baseball" },
  { value: "Fast Pitch", label: "Fast Pitch" },
];

export const genderList = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const ageGroupList = [
  { value: "U_6", label: "U6" },
  { value: "U_8", label: "U8" },
  { value: "U_10", label: "U10" },
  { value: "U_12", label: "U12" },
  { value: "U_14", label: "U14" },
  { value: "U_16", label: "U16" },
  { value: "U_18", label: "U18" },
];

export const classList = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "REC ", label: "REC" },
  { value: "Other", label: "Other" },
];
export const TeamCheckList = [
  { value: "unregister", label: "Unregister" },
  { value: "register", label: "Register" },
];

export const formateTTime = (dateStr) => {
  const date = new Date(dateStr);
  const options = { hour: "numeric", minute: "2-digit", hour12: true };
  const timeFormatted = date.toLocaleString("en-US", options);
  return timeFormatted;
};

export const formatedDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: 'numeric',
    // minute: '2-digit',
    // hour12: true
  };
  const dateFormatted = date.toLocaleString("en-US", options);
  return dateFormatted;
};
