import React, { useEffect, useState } from 'react'

import { ROUTES } from "./Route";
import Login from '../Account/Login';
import TournamentManagementScreen from './TournamentManagementScreen';
import GetRegisterScreen from './GetRegister';
import MagicBoxScreen from './MagicBox';
import TournamentDetailsScreen from './TournamentDetailsScreen';
import CreateTournamentScreen from './CreateTournamentScreen';
import { getAllAgeGroup } from "../../services/generalService";
export default function Tournaments() {

  const [page, setPage] = useState(ROUTES.TOURNAMENT)
  const [allAgeGroups, setAllAgeGroups] = useState([])
  const [slug, setSlug] = useState(null)

  const GetAllAgeGroup = async () => {
    const res = await getAllAgeGroup()
    setAllAgeGroups(res?.data?.data)
  }

  useEffect(() => {
    GetAllAgeGroup()
  }, []);

  switch (page) {
    case ROUTES.TOURNAMENT:
      return <TournamentManagementScreen setPage={setPage} setSlug={setSlug} />
    case ROUTES.TOURNAMENT_DETAILS:
      return <TournamentDetailsScreen allAgeGroups={allAgeGroups} setSlug={setSlug} slug={slug} setPage={setPage}/>
    case ROUTES.CREATE_TOURNAMENT:
      return <CreateTournamentScreen allAgeGroups={allAgeGroups} setPage={setPage} />
    default:
      return <Login />
  }


}
