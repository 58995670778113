import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Tabs from '../../Components/Tabs/Tabs'
import AddTournamentComponent from '../../Components/Modals/AddTournamentDetails';
import DynamicBracket from './Components/TreeComponent3';
import RefundPaymentModal from '../../Components/Modals/RefundPaymentModal';
import { getFixtureBySlug, getTeamsByAgeGroup } from '../../services/generalService';

const TablesContent = ({ allAgeGroups, data }) => {

  const [addModalShow, setAddModalShow] = useState(false)
  const processedGroups = (data?.age_groups?.map(age => ({
    label: age?.label,
    value: age?.value,
    id: age?.id
  })) || []).sort((a, b) => a.id - b.id);
  let age = processedGroups?.length > 0 ? processedGroups[0]?.value : 0;
  const defaultAgeGroup = processedGroups?.length > 0 ? processedGroups[0]?.value : 0;
  const [key, setKey] = useState(defaultAgeGroup);
  // const [teams, setTeams] = useState([])
  const teams = [
    {
      "id": 1,
      "name": "Team 1",
      "age_group": 1,
      "slug": "team-1",
      "created_at": "2022-02-24T11:45:00.000Z",
      "updated_at": "2022-02-24T11:45:00.000Z"
    },
    {
      "id": 2,
      "name": "Team 2",
      "age_group": 1,
      "slug": "team-2",
      "created_at": "2022-02-24T11:45:00.000Z",
      "updated_at": "2022-02-24T11:45:00.000Z"
    },
    {
      "id": 3,
      "name": "Team 3",
      "age_group": 1,
      "slug": "team-1",
      "created_at": "2022-02-24T11:45:00.000Z",
      "updated_at": "2022-02-24T11:45:00.000Z"
    },
    {
      "id": 4,
      "name": "Team 4",
      "age_group": 1,
      "slug": "team-2",
      "created_at": "2022-02-24T11:45:00.000Z",
      "updated_at": "2022-02-24T11:45:00.000Z"
    },
  ]
  const [bracket, setBracket] = useState({})
  const [addRefundModalShow, setAddRefundModalShow] = useState(false)

  useEffect(() => {
    if (key !== null) {
      GetTeamsByAgeGroup()
      GetFixtureBySlug()
    }
  }, [key])

  const GetTeamsByAgeGroup = async () => {
    const res = await getTeamsByAgeGroup(key)
    // setTeams(res?.data?.data)
  }

  const GetFixtureBySlug = async () => {
    const res = await getFixtureBySlug(key)
    setBracket(res?.data?.data)
  }

  const inlineItemStyles = {
    background: 'linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(157,157,157,1) 35%)',
    margin: '5px',
    padding: '10px 20px',
  };


  return (
    <div className='p-2'>
      <p className=" text-dark m-0 fw-bold fs-5 mt-5 text-dark">
        Team Age Group
      </p>

      <Row>
        <Col className='p-2 text-dark'>
          <Tabs tabs={processedGroups} current={key} onChange={setKey} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
          <span>Teams: {teams?.length}</span>
        </Col>
      </Row>

      {
        // tabContent() 
      }
      {/* Add Team Details */}
      {
        !data?.is_live ? <div className='d-flex justify-content-end text-dark'>
          <Button style={{ marginRight: '20px' }} onClick={() => setAddRefundModalShow(true)} >
            Refund
          </Button>


          {/* <Button disabled={teams?.length > 4 ? false : true} onClick={() => setAddModalShow(true)} >
            Add Bracket
          </Button> */}
          <Button onClick={() => setAddModalShow(true)} >
            Add Bracket
          </Button>

        </div> : ''
      }


      <Row>
        <Col>
          <DynamicBracket ageGroupId={key} />
        </Col>
      </Row>
      {addModalShow ?
        <AddTournamentComponent teams={teams} ageGroupId={key} setAddModalShow={setAddModalShow} handleAddModalClose={() => setAddModalShow(false)} />
        : ""}
      {addRefundModalShow ?
        <RefundPaymentModal teams={teams} handleAddModalClose={() => setAddRefundModalShow(false)} />
        : ""}
      {/* <ThemeModal className={""} title={"Delete"} size={"sm"}
        content={delModalShow ? <AddTournamentComponent /> : ''}
        show={delModalShow}
        onHide={() => setDelModalShow(false)} /> */}
    </div>
  )
}

export default TablesContent
