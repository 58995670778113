import React, { useState } from "react";
import { ROUTES } from "./Route";
import styles from "./index.module.css";
import "./createTournamentScreen.css";
import {
  BackButton,
  Button,
  CustomInput,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import { Col, Row, Form } from "react-bootstrap";
import SelectFieldComponent, {
  CustomSelect,
} from "./Components/SelectFieldComponent";
import { BiSolidDownArrow } from "react-icons/bi";
import { Select, Space } from "antd";
import { FaPlusCircle } from "react-icons/fa";
import { message, Upload } from "antd";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import { sportsStateList, sportsTypeList, classList } from "../../constants";
import Toast from "../../Hooks/useToast";
import { createTournament } from "../../services/FormDataService";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;

const CreateTournamentScreen = ({ allAgeGroups, setPage, GetScheduleTournament }) => {
  const [mediaImage, setMediaImage] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleFileChange = (info) => {
    setMediaImage(info.file.originFileObj);
  };

  const handleBackButtonClick = () => {
    setPage(ROUTES.TOURNAMENT);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      Toast("You can only upload JPG/PNG file!", "error", false);
    } else if (!isLt2M) {
      Toast("Image must smaller than 2MB!", "error", false);
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // upload files
  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: "image/jpeg,image/png,image/png",
    value: !_.isObject(mediaImage)
      ? mediaImage
      : URL.createObjectURL(mediaImage),
    beforeUpload: beforeUpload, 
    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setMediaImage(info.file.originFileObj);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaImage("");
    },
  };

  const thumbnailProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: "image/jpeg,image/png,image/png",
    value: !_.isObject(thumbnailImage)
      ? thumbnailImage
      : URL.createObjectURL(thumbnailImage),
    beforeUpload: beforeUpload, 
    customRequest: dummyRequest, 
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setThumbnailImage(info.file.originFileObj);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setThumbnailImage("");
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleCreateTournament = async (data) => {
    const ageGroupsString = data?.age_groups?.toString();
    const ageGroupsList = ageGroupsString
      ?.split(",")
      ?.map((item) => item.trim());
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("location", data?.location);
    formData.append("state", data?.state);
    formData.append("zip_code", data?.zip_code);
    formData.append("description", data?.description);
    formData.append("sports_type", data?.sports_type);
    formData.append("class", data?.class);
    formData.append("ticket_price", data?.ticket_price);
    formData.append("rules", data?.rules);
    formData.append("thumbnail_url", thumbnailImage);
    formData.append("start_date", data?.start_date);
    formData.append("end_date", data?.end_date);
    formData.append("registration_start_date", data?.registration_start_date);
    formData.append("registration_end_date", data?.registration_end_date);
    formData.append("registration_fee", data?.registration_fee);
    formData.append("media_url", mediaImage);
    ageGroupsList?.forEach((ageGroup) => {
      formData.append("age_groups", ageGroup);
    });
    try {
      // Use unwrap() to get the response data directly
      const response = await createTournament(formData);
      Toast(response?.data?.message, "success");
      setPage(ROUTES.TOURNAMENT)
    } catch (error) {
      Toast(error?.response?.data?.message, "error");
    }
  };
  return (
    <div className="w-100">
      <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />
      <SubTitle text={"Create Tournament"} classes="text-dark" />
      <Form onSubmit={handleSubmit(handleCreateTournament)}>
        <Row className={`w-100 mt-2 mb-5`} gutter={[0, 24]}>
          <Col className={` mt-2 mb-2`} lg={6} md={6} sm={12}>
            <div style={{ height: "150px", paddingBottom: "8px" }}>
              <Dragger {...props}>
                {!_.isEmpty(mediaImage) ? (
                  <img
                    src={
                      !_.isObject(mediaImage)
                        ? `${mediaImage}`
                        : `${URL.createObjectURL(mediaImage)}`
                    }
                    alt="avatar"
                    style={{ width: "100%", height: "100px" }}
                    className="img-fluid"
                  />
                ) : (
                  <>
                    <p className="ant-upload-drag-icon">
                      <FaPlusCircle />
                    </p>
                    <p className="ant-upload-text">Add Media</p>
                    <p className="ant-upload-hint"> or drag and drop </p>
                  </>
                )}
              </Dragger>
            </div>




            <Row>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="name"
                  rules={{
                    required: { value: true, message: "Required Field" },
                    maxLength: { value: 100, message: "max Length is 100" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      onChange={onChange}
                      classes="mt-2 mb-3"
                      placeholder="Tournament Name"
                      type="text"
                    />
                  )}
                />
                {errors.name && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.name.message}
                  </p>
                )}
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="location"
                  rules={{
                    required: { value: true, message: "Required Field" },
                    maxLength: { value: 100, message: "max Length is 100" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      onChange={onChange}
                      classes="mt-2 mb-3"
                      type="text"
                      placeholder="Tournament Location"
                    />
                  )}
                />
                {errors.location && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.location.message}
                  </p>
                )}
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="state"
                  rules={{
                    required: { value: true, message: "Required Field" },
                    maxLength: { value: 100, message: "max Length is 100" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      onChange={onChange}
                      classes="mt-2 mb-3"
                      type="text"
                      placeholder="State"
                    />
                  )}
                />
                {errors.location && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.location.message}
                  </p>
                )}
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="zip_code"
                  rules={{
                    required: { value: true, message: "Required Field" },
                    maxLength: { value: 100, message: "max Length is 100" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      onChange={onChange}
                      classes="mt-2 mb-3"
                      type="text"
                      placeholder="Zip Code"
                    />
                  )}
                />
                {errors.location && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.location.message}
                  </p>
                )}
              </Col>
              <Col className="col-sm-12">
                <Controller
                  control={control}
                  name="description"
                  rules={{
                    required: { value: true, message: "Required Field" },
                    maxLength: { value: 240, message: "max Length is 240" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      onChange={onChange}
                      classes="mt-2 mb-3"
                      placeholder="Description"
                      as="textarea"
                      rows={5}
                    />
                  )}
                />
                {errors.description && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.description.message}
                  </p>
                )}
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="sports_type"
                  rules={{
                    required: { value: true, message: "Required Field" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      placeholder="Select Sports"
                      options={sportsTypeList}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.sports_type && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.sports_type.message}
                  </p>
                )}
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="class"
                  rules={{
                    required: { value: true, message: "Required Field" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      placeholder="Select Class"
                      options={classList}
                      onChange={onChange}
                    />
                  )}
                />
              </Col>
              <Col className="col-sm-12">
              
                <Controller
                  control={control}
                  name="age_groups"
                  rules={{
                    required: { value: true, message: "Required Field" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      mode="multiple"
                      // allowClear
                      style={{
                        width: "100%",
                        background: "#fff",
                        color: "#000",
                      }}
                      placeholder="Select Age Group"
                      // defaultValue={['a10', 'c12']}
                      // onChange={handleChange}
                      options={allAgeGroups}
                      onChange={onChange}
                      suffixIcon=<BiSolidDownArrow />
                      showSearch={false}
                      size="small"
                    />
                  )}
                />
              </Col>
            </Row>
          </Col>

          <Col className={` mt-2 mb-2`} lg={6} md={6} sm={12}>
            <div style={{ height: "90px", marginBottom: "8px" }}>
              <Row>
                <Col xs="2">
                  <div
                    style={{
                      width: "90px",
                      height: "90px",
                      background: "rgb(119, 124, 133)",
                      borderRadius: "16px",
                    }}
                  >
                    {_.isObject(thumbnailImage) ? (
                      <img
                        src={
                          !_.isObject(thumbnailImage)
                            ? `${thumbnailImage}`
                            : `${URL.createObjectURL(thumbnailImage)}`
                        }
                        alt="avatar"
                        style={{
                          width: "87px",
                          borderRadius: "25%",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        className="img-fluid"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col>
                  <Dragger {...thumbnailProps}>
                    <Row>
                      <Col xs="2">
                        <p className="ant-upload-drag-icon">
                          <FaPlusCircle />
                        </p>
                      </Col>

                      <Col className="align-content-end">
                        <p className="ant-upload-text">Add Thumbnail</p>
                        <p className="ant-upload-hint"> or drag and drop </p>
                      </Col>
                    </Row>
                  </Dragger>
                </Col>
              </Row>
            </div>
            
            <Row>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="ticket_price"
                  rules={{
                    required: { value: true, message: "Required Field" },
                    maxLength: { value: 100, message: "max Length is 100" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      onChange={onChange}
                      classes="mt-2 mb-3"
                      type="number"
                      placeholder="Ticket Price"
                    />
                  )}
                />
                {errors.ticket_price && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.ticket_price.message}
                  </p>
                )}
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="registration_fee"
                  rules={{
                    required: { value: true, message: "Required Field" },
                    maxLength: { value: 100, message: "max Length is 100" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      onChange={onChange}
                      classes="mt-2 mb-3"
                      type="number"
                      placeholder="Registration Fee"
                    />
                  )}
                />    
                {errors.registration_fee && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.registration_fee.message}
                  </p>
                )}
              </Col>
              <Col className="col-sm-12">
                <Controller
                  control={control}
                  name="rules"
                  rules={{
                    required: { value: true, message: "Required Field" },
                    maxLength: { value: 240, message: "max Length is 240" },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      onChange={onChange}
                      classes="mt-2 mb-3"
                      type="text"
                      placeholder="Rules and Guideline"
                      as="textarea"
                      rows={5}
                    />
                  )}
                />
                {errors.rules && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.rules.message}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="start_date"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <Form.Label className={styles.dateLabel}>
                        Start Date
                      </Form.Label>
                      <Form.Control
                        className={`${styles.datePicker} mt-2 mb-3`}
                        name="start_date"
                        type="datetime-local"
                        placeholder="Start Date"
                        onChange={onChange}
                      />
                    </>
                  )}
                />
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="end_date"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <Form.Label className={styles.dateLabel}>
                        End Date
                      </Form.Label>
                      <Form.Control
                        className={`${styles.datePicker} mt-2 mb-3`}
                        name="end_date"
                        type="datetime-local"
                        onChange={onChange}
                      />
                    </>
                  )}
                />
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="registration_start_date"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <Form.Label className={styles.dateLabel}>
                        Registration Start Date
                      </Form.Label>
                      <Form.Control
                        className={`${styles.datePicker} mt-2 mb-3`}
                        name="registration_start_date"
                        type="datetime-local"
                        onChange={onChange}
                      />
                    </>
                  )}
                />
              </Col>
              <Col className="col-sm-12 col-md-6">
                <Controller
                  control={control}
                  name="registration_end_date"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <Form.Label className={styles.dateLabel}>
                        Registration End Date
                      </Form.Label>
                      <Form.Control
                        className={`${styles.datePicker} mt-2 mb-3`}
                        name="registration_end_date"
                        type="datetime-local"
                        onChange={onChange}
                      />
                    </>
                  )}
                />
              </Col>
            </Row>

            <Button
              type="submit"
              text="Publish Tournament"
            ></Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateTournamentScreen;
