import React, { useEffect, useState } from 'react'

import { ROUTES } from "./Route";
import UserManagementScreen from './UserManagementScreen';
import UserCreateScreen from './UserCreateScreen';
import UserDetailScreen from './UserDetailScreen';

export default function UserManagement() {

    const [page, setPage] = useState(ROUTES.USER_MANAGEMENT)
    const [slug, setSlug] = useState(null)



    switch (page) {
        case ROUTES.USER_MANAGEMENT:
            return <UserManagementScreen setPage={setPage} setSlug={setSlug} />
        case ROUTES.USER_CREATE:
            return <UserCreateScreen setPage={setPage} setSlug={setSlug} />
        case ROUTES.USER_DETAILS:
            return <UserDetailScreen setPage={setPage} userId={slug}  />
        default:
            return <UserManagementScreen setPage={setPage} setSlug={setSlug}/>
    }


}
