import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ModalContainer from "../ModalContainer";
import { FaCircleXmark } from "react-icons/fa6";
import { Col, Row, Form } from 'react-bootstrap';
import { Button } from "../CustomComponents";
import { DatePicker, Space } from 'antd';
import { useForm, Controller } from "react-hook-form"

import styles from '../../Pages/TournamentManagement/index.module.css'
import cssStyles from "./styles/notification.module.css";
import './styles/AddTournament.css'

import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import { createFixture } from "../../services/generalService";
import Toast from "../../Hooks/useToast";

const { RangePicker } = DatePicker;

const AddTournamentComponent = ({ teams, ageGroupId, setAddModalShow, handleAddModalClose, data }) => {
    const [allteams, setAllTeams] = useState([])

    useEffect(() => {
        RestructureData()
    }, [teams])

    const RestructureData = () => {
        const res = teams?.map((dt) => ({
            value: dt?.registration_slug,
            label: dt?.name
        }))
        setAllTeams(res)
    }

    const dateFormat = 'YYYY-MM-DD';
    //#region States and custom funstions
    const [minDate, setMinDate] = useState(dayjs().format(dateFormat));
    const [rowsNumber, setRowsNumber] = useState(0)
    const [rounds, setRounds] = useState({ first: 0, second: 0, third: 0, fourth: 0 });
    const [firstRound, setFirstRound] = useState([]);
    const [secondRound, setSecondRound] = useState([]);
    const [thirdRound, setThirdRound] = useState([]);
    const [fourthRound, setFourthRound] = useState([]);
    const [fifthRound, setFifthRound] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]); // State to store selected teams
    console.log(firstRound, secondRound, thirdRound, fourthRound, fifthRound);
    const { handleSubmit, control, register } = useForm()

    // picking the date on onChange
    const formattedDate = (originalDate) => dayjs(originalDate).format('YYYY-MM-DD HH:mm:ss');

    // Setting up min Date for next
    const formatedDateToset = (originalDate) => dayjs(originalDate).format('YYYY-MM-DD')

    const formattedCurrentHour = (originalDate) => {
        const hourString = dayjs(originalDate).format('HH');
        const parsedHour = parseInt(hourString, 10);
        const formattedHour = parsedHour.toString();
        return formattedHour;
    };

    const currentDateAndTime = formattedCurrentHour(new Date())
    const [time, setTime] = useState(currentDateAndTime)

    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };
    // const disabledRangeTime = (_, type) => {
    //     if (type === 'start') {
    //         return {
    //             disabledHours: () => range(0, 60).splice(0, time),
    //             disabledMinutes: () => range(30, 60),
    //             disabledSeconds: () => [55, 56],
    //         };
    //     }
    //     return {
    //         disabledHours: () => range(0, 60).splice(0, time),
    //         disabledMinutes: () => range(0, 31),
    //         disabledSeconds: () => [55, 56],
    //     };
    // };
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    const disabledDateTime = () => ({
        disabledHours: () => range(0, 24).splice(0, time),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
    });

    const DemoData = [
        { value: 1, label: 'Team A' },
        { value: 2, label: 'Team B' },
        { value: 3, label: 'Team C' },
        { value: 4, label: 'Team D' },

        { value: 5, label: 'Team AA' },
        { value: 6, label: 'Team AB' },
        { value: 7, label: 'Team AC' },
        { value: 8, label: 'Team AD' },
    ]

    const TotalRowsToOpen = teams?.length % 2 == 0 ?
        (teams?.length == 4 || teams?.length == 8 || teams?.length == 16 || teams?.length == 32 ?
            teams?.length / 2 :
            0) :
        (teams?.length - 1) / 2;

    useEffect(() => {
        const currentDate = dayjs(minDate).format(dateFormat);
    }, [minDate]);

    useEffect(() => {
        if (TotalRowsToOpen === 16) {
            setRounds({ first: 8, second: 4, third: 2, fourth: 1 });
        } else if (TotalRowsToOpen === 8) {
            setRounds({ first: 0, second: 4, third: 2, fourth: 1 });
        } else if (TotalRowsToOpen === 4) {
            setRounds({ first: 0, second: 0, third: 2, fourth: 1 });
        } else if (TotalRowsToOpen === 2) {
            setRounds({ first: 0, second: 0, third: 0, fourth: 1 });
        } else {
            setRounds({ first: 0, second: 0, third: 0, fourth: 0 });
        }
    }, [TotalRowsToOpen]);


    const handleInputChange = (index, fieldName, value, groupfieldName, group, start_date, end_date) => {
        setFirstRound(prevState => {
            const newData = [...prevState];
            newData[index] = {
                ...newData[index],
                ...(fieldName && { [fieldName]: value }), // Only include fieldName if it's defined
                ...(groupfieldName && { [groupfieldName]: group }), // Only include groupfieldName if it's defined
                ...(start_date && { ['start_date']: start_date }), // Only include start_date if it's defined
                ...(end_date && { ['end_date']: end_date }), // Only include end_date if it's defined
            };
            return newData;
        })
        setSelectedTeams((prevSelectedTeams) => [...prevSelectedTeams, value]);
    };


    const handleInputSecondChange = (index, groupfieldName, group, start_date, end_date) => {
        setSecondRound(prevState => {
            const newData = [...prevState];
            newData[index] = {
                ...newData[index],
                ...(groupfieldName && { [groupfieldName]: group }), // Only include groupfieldName if it's defined
                ...(start_date && { ['start_date']: start_date }), // Only include start_date if it's defined
                ...(end_date && { ['end_date']: end_date }), // Only include end_date if it's defined
            };
            return newData;
        });
    };

    const handleInputThirdChange = (index, groupfieldName, group, start_date, end_date) => {
        setThirdRound(prevState => {
            const newData = [...prevState];
            newData[index] = {
                ...newData[index],
                ...(groupfieldName && { [groupfieldName]: group }), // Only include groupfieldName if it's defined
                ...(start_date && { ['start_date']: start_date }), // Only include start_date if it's defined
                ...(end_date && { ['end_date']: end_date }), // Only include end_date if it's defined
            };
            return newData;
        });
    };

    const handleInputFourthChange = (index, groupfieldName, group, start_date, end_date) => {
        setFourthRound(prevState => {
            const newData = [...prevState];
            newData[index] = {
                ...newData[index],
                ...(groupfieldName && { [groupfieldName]: group }), // Only include groupfieldName if it's defined
                ...(start_date && { ['start_date']: start_date }), // Only include start_date if it's defined
                ...(end_date && { ['end_date']: end_date }), // Only include end_date if it's defined
            };
            return newData;
        });
    };

    const handleInputFifthChange = (index, groupfieldName, group, start_date, end_date) => {
        setFifthRound(prevState => {
            const newData = [...prevState];
            newData[index] = {
                ...newData[index],
                ...(groupfieldName && { [groupfieldName]: group }), // Only include groupfieldName if it's defined
                ...(start_date && { ['start_date']: start_date }), // Only include start_date if it's defined
                ...(end_date && { ['end_date']: end_date }), // Only include end_date if it's defined
            };
            return newData;
        });
    };

    // Format Data:

    // const formatMatchData = () => {

    //     // Function to add match data to the formatted object
    //     // const addMatchData = (round, matchData) => {
    //     //     if (!formattedData[round]) {
    //     //         formattedData[round] = [];
    //     //     }
    //     //     formattedData[round].push(matchData);
    //     // };

    //     // // Add match data from each round to the formatted object
    //     // let count = 1;
    //     // firstRound.forEach(match => addMatchData('1', match));
    //     // secondRound.forEach(match => addMatchData('2', match));
    //     // thirdRound.forEach(match => addMatchData('3', match));
    //     // fourthRound.forEach(match => addMatchData('4', match));
    //     // fifthRound.forEach(match => addMatchData('5', match));

    //     // return formattedData;
    // };
    // const formattedMatchData = formatMatchData();
    //#endregion
    const handleDataSubmit = async (e) => {

        const Allrounds = [firstRound, secondRound, thirdRound, fourthRound];
        console.log(Allrounds, 'all round');
        const formattedData = Allrounds?.filter(round => round.length > 0)
            ?.map((round, index) => (round));
        console.log(formattedData, 'formattedDataformattedDataformattedData');

        const payload = { fixtures: {} }
        let i = 0;
        for (i = 0; i < formattedData.length; i++) {
            const record = formattedData[i];
            payload.fixtures[`${i + 1}`] = record

        }
        payload.fixtures[`${i + 1}`] = fifthRound[0]
        try {
            const res = await createFixture(ageGroupId, payload)
            setAddModalShow(false)
            Toast(res?.data?.message, "success");
        } catch (e) {
            Toast(e?.response?.data?.message, "error");
        }

    }

    // ALL Logs
    // console.log(fisrstRound, 'fisrstRoundfisrstRound')
    // console.log(secondRound, 'secondRoundsecondRound')
    // console.log(thirdRound, 'thirdRoundthirdRound')
    // console.log(fourthRound, 'fourthRoundfourthRound')
    // console.log(fifthRound, 'fifthRoundfifthRound')

    console.log(selectedTeams, 'selectedTeams');
    const filteredOptions = teams.filter((option) => option.value in selectedTeams);



    return (
        <div>
            <ModalContainer handleClose={handleAddModalClose}>
                <div className={cssStyles.modalContainer}>
                    <div className="d-flex align-items-center w-100 justify-content-between mb-4">
                        <p className="fs-4 fw-medium m-0"> {data ? "Edit Tournament" : "Add Tournament"} </p>
                        <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
                            <FaCircleXmark
                                role="button"
                                onClick={handleAddModalClose}
                                style={{ cursor: "pointer" }}
                            />
                        </span>
                    </div>
                    <div className="w-100" style={{ cursor: 'pointer' }}>
                        {/* <Row>
                            <div className="icon mb-3 disabled" onClick={handleAddRow}>
                                <span className="m-2">
                                    <MdAddCircle />
                                </span>
                                <span className="m-1">
                                    ADD More
                                </span>
                            </div>
                        </Row> */}
                        <Form onSubmit={handleSubmit(handleDataSubmit)}>
                            {/* First Round */}
                            {[...Array(TotalRowsToOpen && TotalRowsToOpen)]?.map((_, index) => {
                                const group = index % 2 === 0 ? 'A' : 'B';
                                return (
                                    <>
                                        <div className="mb-5">
                                            <Row key={index} className="mb-3">
                                                <Col md={5}>
                                                    <p className="m-0" style={{ color: "#0AA7FF" }}>
                                                        SELECT TEAM
                                                    </p>
                                                    <Controller
                                                        control={control}
                                                        name="teamA_slug"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <>
                                                                <SelectFieldComponent
                                                                    // Assuming onSelectChange is a callback provided by SelectFieldComponent to handle selection changes
                                                                    onSelectChange={(selectedValue) => handleInputChange(index, 'teamA_slug', selectedValue?.target?.value, 'group', group)}
                                                                    value={value} // Pass the value from react-hook-form to maintain the selected value
                                                                    optionsList={allteams} // Pass the filtered options list
                                                                />
                                                            </>
                                                        )} />
                                                </Col>
                                                <Col md={2} style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                }} >
                                                    <span style={{
                                                        fontSize: '12px'
                                                    }}>
                                                        <p style={{
                                                            margin: '0px'
                                                        }}>
                                                            Vs
                                                        </p>
                                                        <p style={{
                                                            margin: '0px'
                                                        }}>
                                                            {`GROUP ${group}`}
                                                        </p>
                                                    </span>
                                                </Col>
                                                <Col md={5}>
                                                    <p className="m-0" style={{ color: "#0AA7FF" }}>
                                                        SELECT TEAM
                                                    </p>
                                                    <Controller
                                                        control={control}
                                                        name="teamB_slug"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <>
                                                                <SelectFieldComponent
                                                                    optionsList={allteams} // Pass the filtered options list
                                                                    value={value}
                                                                    onSelectChange={(selectedValue) => handleInputChange(index, 'teamB_slug', selectedValue?.target?.value)}
                                                                />
                                                            </>
                                                        )} />
                                                </Col>
                                            </Row >

                                            <Row style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center'
                                            }}>
                                                <Col md={12}>
                                                    <div style={{
                                                        width: '100%'
                                                    }}>
                                                        <Controller
                                                            control={control}
                                                            name="end_date"
                                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                <>
                                                                    <Form.Label className={styles.dateLabel}>Set Match Date And Time</Form.Label>

                                                                    <RangePicker
                                                                        // disabledDate={disabledDate}
                                                                        // disabledTime={disabledDateTime}
                                                                        showTime={{
                                                                            format: 'HH:00:00',
                                                                            // hideDisabledOptions: true,
                                                                        }}
                                                                        allowClear={false}
                                                                        minDate={dayjs(minDate)}
                                                                        onChange={(date) => {
                                                                            setMinDate(formatedDateToset(date?.[1]))
                                                                            handleInputChange(index, '', '', '', '', formattedDate(date?.[0]), formattedDate(date?.[1]))
                                                                            setTime(formattedCurrentHour(date?.[0]))
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                )

                            })}

                            {/* Second Round */}
                            <div className={`d-flex align-items-center w-100 justify-content-between mb-4
                            ${TotalRowsToOpen == 16 ? 'd-block border-top' : 'd-none'}`}>
                                <p className="fs-4 fw-medium m-0 mt-3"></p>
                            </div>

                            {[...Array(rounds?.first)].map((_, index) => {
                                const group = index % 2 === 0 ? 'A' : 'B';
                                return (

                                    <div className="mb-5" key={index}>
                                        <Row style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Col md={12}>
                                                <div style={{
                                                    width: '100%'
                                                }}>
                                                    <Controller
                                                        control={control}
                                                        name="end_date"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <>
                                                                <Form.Label className={styles.dateLabel}>Set Match Date And Time</Form.Label>
                                                                <RangePicker showTime={{
                                                                    format: 'HH'
                                                                }}
                                                                    minDate={dayjs(minDate)}
                                                                    allowClear={false}
                                                                    onChange={(date) => {
                                                                        setMinDate(formatedDateToset(date?.[1]))
                                                                        handleInputSecondChange(index, 'group', group, formattedDate(date?.[0]), formattedDate(date?.[1]))
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            }
                            )}

                            {/* Third Round */}
                            <div className={`d-flex align-items-center w-100 justify-content-between mb-4
                            ${TotalRowsToOpen == 16 || TotalRowsToOpen == 8 ? 'd-block border-top' : 'd-none'}`}>
                                <p className="fs-4 fw-medium m-0 mt-3"> Select Time For Quater Final </p>
                            </div>
                            {[...Array(rounds?.second)].map((_, index) => {
                                const group = index % 2 === 0 ? 'A' : 'B';
                                return (
                                    <div className="mb-5" key={index}>
                                        <Row style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Col md={12}>
                                                <div style={{
                                                    width: '100%'
                                                }}>
                                                    <Controller
                                                        control={control}
                                                        name="end_date"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <>
                                                                <Form.Label className={styles.dateLabel}>Set Match Date And Time</Form.Label>
                                                                <RangePicker showTime={{
                                                                    format: 'HH'
                                                                }}
                                                                    allowClear={false}
                                                                    minDate={dayjs(minDate)}
                                                                    onChange={(date) => {
                                                                        setMinDate(formatedDateToset(date?.[1]))
                                                                        handleInputThirdChange(index, 'group', group, formattedDate(date?.[0]), formattedDate(date?.[1]))
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            }
                            )}

                            {/* Fourth Round */}
                            <div className={`d-flex align-items-center w-100 justify-content-between mb-4
                            ${TotalRowsToOpen == 16 || TotalRowsToOpen == 8 || TotalRowsToOpen == 4 ? 'd-block border-top' : 'd-none'}`}>
                                <p className="fs-4 fw-medium m-0 mt-3"> Select Time For Semi Final </p>
                            </div>
                            {[...Array(rounds?.third)].map((_, index) => {
                                const group = index % 2 === 0 ? 'A' : 'B';
                                return (
                                    <div className="mb-5" key={index}>
                                        <Row style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Col md={12}>
                                                <div style={{
                                                    width: '100%'
                                                }}>
                                                    <Controller
                                                        control={control}
                                                        name="end_date"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <>
                                                                <Form.Label className={styles.dateLabel}>Set Match Date And Time</Form.Label>
                                                                <RangePicker showTime={{
                                                                    format: 'HH'
                                                                }}
                                                                    allowClear={false}
                                                                    minDate={dayjs(minDate)}
                                                                    onChange={(date) => {
                                                                        setMinDate(formatedDateToset(date?.[1]))
                                                                        handleInputFourthChange(index, 'group', group, formattedDate(date?.[0]), formattedDate(date?.[1]))
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            }
                            )}
                            {/* Fifth Round */}
                            <div className={`d-flex align-items-center w-100 justify-content-between mb-4
                            ${TotalRowsToOpen == 16 || TotalRowsToOpen == 8 || TotalRowsToOpen == 4 || TotalRowsToOpen == 2 ? 'd-block border-top' : 'd-none'}`}>
                                <p className="fs-4 fw-medium m-0 mt-3"> Select Time For Final </p>
                            </div>
                            {[...Array(rounds?.fourth)].map((_, index) => {
                                const group = index % 2 === 0 ? 'A' : 'B';
                                return (
                                    <div className="mb-5" key={index}>
                                        <Row style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Col md={12}>
                                                <div style={{
                                                    width: '100%'
                                                }}>
                                                    <Controller
                                                        control={control}
                                                        name="end_date"
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <>
                                                                <Form.Label className={styles.dateLabel}>Set Match Date And Time</Form.Label>
                                                                <RangePicker showTime={{
                                                                    format: 'HH'
                                                                }}
                                                                    minDate={dayjs(minDate)}
                                                                    allowClear={false}
                                                                    onChange={(date) => {
                                                                        setMinDate(formatedDateToset(date?.[1]))
                                                                        handleInputFifthChange(index, 'group', group, formattedDate(date?.[0]), formattedDate(date?.[1]))
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            }
                            )}

                            <Row>
                                {TotalRowsToOpen === 0 ? <p className="d-flex justify-content-center">No Teams Available</p> : <Col md={12}>
                                    <Button
                                        classes={`${cssStyles.cardBtn} mt-4 `}
                                        text={data ? "Update" : "Save"}
                                        type='submit'
                                    ></Button>
                                </Col>}

                            </Row>
                        </Form>
                    </div>
                </div>
            </ModalContainer >
        </div >
    );
};

export default AddTournamentComponent;
