import React from 'react';
// import styles from './TreeComponent2.css';

const BracketTable = () => {

  return (
    <div className="bracket">
        <svg width="2000" height="1600" xmlns="http://www.w3.org/2000/svg">
          <text x="20" y="20" font-size="16" font-weight="bold">Winner's Bracket</text>

          <rect x="205" y="20" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="215" y="40" font-size="12">Team 1</text>
          <rect x="205" y="185" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="215" y="205" font-size="12">Team 2</text>
          <line x1="325" y1="35" x2="355" y2="35" stroke="black" />
          <line x1="325" y1="200" x2="355" y2="200" stroke="black" />
          <line x1="355" y1="35" x2="355" y2="200" stroke="black" />

          <rect x="205" y="340" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="215" y="360" font-size="12">Team 3</text>
          <rect x="205" y="505" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="215" y="525" font-size="12">Team 4</text>
          <line x1="325" y1="355" x2="355" y2="355" stroke="black" />
          <line x1="325" y1="520" x2="355" y2="520" stroke="black" />
          <line x1="355" y1="355" x2="355" y2="520" stroke="black" />

          <rect x="355" y="90" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="365" y="110" font-size="12">Team 1</text>
          <rect x="355" y="420" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="365" y="440" font-size="12">Team 2</text>
          <line x1="475" y1="95" x2="655" y2="95" stroke="black" />
          <line x1="475" y1="425" x2="655" y2="425" stroke="black" />
          <line x1="655" y1="95" x2="655" y2="425" stroke="black" />

          <rect x="655" y="265" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="675" y="285" font-size="12">Team 1</text>

          <text x="500" y="605" font-size="16" font-weight="bold">Loser's Bracket</text>

          <rect x="505" y="655" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="525" y="675" font-size="12">Loser 1</text>
          <rect x="505" y="935" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="525" y="955" font-size="12">Loser 2</text>
          <line x1="625" y1="665" x2="655" y2="665" stroke="black" />
          <line x1="625" y1="950" x2="655" y2="950" stroke="black" />
          <line x1="655" y1="665" x2="655" y2="950" stroke="black" />

          <rect x="655" y="610" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="675" y="630" font-size="12">Loser 1</text>
          <rect x="655" y="745" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="675" y="765" font-size="12">Loser 2</text>
          <line x1="775" y1="625" x2="805" y2="625" stroke="black" />
          <line x1="775" y1="760" x2="805" y2="760" stroke="black" />
          <line x1="805" y1="625" x2="805" y2="760" stroke="black" />

          <rect x="805" y="675" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="825" y="695" font-size="12">Loser 1</text>

          <line x1="775" y1="280" x2="1005" y2="280" stroke="black" />
          <line x1="925" y1="690" x2="1005" y2="690" stroke="black" />
          <line x1="1005" y1="280" x2="1005" y2="690" stroke="black" />
          
          <rect x="1005" y="495" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="1025" y="515" font-size="12">winner 1</text>
          <rect x="1005" y="775" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="1025" y="795" font-size="12">loser 2</text>
          <line x1="1125" y1="510" x2="1155" y2="510" stroke="black" />
          <line x1="1125" y1="790" x2="1155" y2="790" stroke="black" />
          <line x1="1155" y1="510" x2="1155" y2="790" stroke="black" />

          <rect x="1155" y="625" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="1175" y="645" font-size="12">winner 1</text>
      </svg>

    </div>
  );
};

export default BracketTable;