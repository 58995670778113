import React, { useEffect, useState } from 'react'
import Tabs from '../../Components/Tabs/Tabs'
import { Col, Row } from 'react-bootstrap';
import TeamsTabContent from './TeamsTabContent';
import { getTeamsByAgeGroup } from '../../services/generalService';

const TeamsContent = ({ data }) => {
  const [teams, setTeams] = useState([])
  const processedGroups = (data?.age_groups?.map(age => ({
    label: age?.label,
    value: age?.value,
    id: age?.id
  })) || []).sort((a, b) => a.id - b.id);
  const defaultAgeGroup = processedGroups.length > 0 ? processedGroups[0]?.value : 0;
  const [key, setKey] = useState(defaultAgeGroup);

  useEffect(() => {
    if (key !== null) {
      GetTeamsByAgeGroup()
    }
  }, [key])

  const GetTeamsByAgeGroup = async () => {
    const res = await getTeamsByAgeGroup(key)
    setTeams(res?.data?.data)
  }

  const inlineItemStyles = {
    background: '#8d8d8d',
    margin: '5px',
    padding: '10px 20px',
  };



  return (
    <div className='p-2'>
      {/* <SubTitle text='Teams Age Group' /> */}
      <p className=" text-dark m-0 fw-bold fs-5 mt-5">
        Team Age Group
      </p>
      <Row>
        <Col className='p-2'>
          <Tabs tabs={processedGroups} current={key} onChange={setKey} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
        </Col>
      </Row>


      <TeamsTabContent teams={teams} getTeams={GetTeamsByAgeGroup} />

    </div>
  )
}

export default TeamsContent
