import React, { useEffect, useState } from "react";
import styles from "./styles/user_management.module.css";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Toast from "../../Hooks/useToast";
import { getUserById } from "../../services/UserService";
import { IoIosRemoveCircle } from "react-icons/io";
import { Button, Card, Col, Row } from "react-bootstrap";
import { updateUser } from "../../services/UserService";
import EditUserModal from "../../Components/Modals/EditUserModal";
import { ROUTES } from "./Route";
// import { set } from "lodash";

const UserDetailScreen = ({ setPage, userId }) => {
  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  function indication() {
    return indicationMessage;
  }

  const EditUser = async (data) => {
    if (data !== null || data !== undefined) {

        const res = await updateUser(userId, data);

        if (res.status === 200) {
            Toast(res?.data?.message, "success");
            setEditModal(false);
        } else {
            console.log('Error editing Team');
        }
    }
  }

  const handleEditModalClose = () => {
    setEditModal(!editModal)
  };

  const GetUserById = async () => {
    try {
      let result = await getUserById(userId);
      setData(result?.data?.data);
      setIndicationMessage("");
      Toast(result?.data?.message, "success");
    } catch (e) {
      Toast(e?.response?.message, "error");
    }
  };

  useEffect(() => {
    GetUserById();
  }, []);


  return (
    <div className={styles.MainContainer}>
      {/* {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          deletetxt={"Delete"}
          data={rowData}
          id={userId}
          onDelete={DeleteUser}
        />
      ) : (
        ""
      )}
      {editModal ? (
        <EditUserModal
            show={editModal}
            handleEditModalClose={handleEditModalClose}
            onEdit={EditTeam}
            data={details}
            edittxt={"Save Changes"}
        />
      ) : (
          ""
      )} */}

      <div className={`${styles.headerContainer} mb-2`}>
        <div>
          <Button
            classes={`${styles.cardBtn} `}
            onClick={() => setPage(ROUTES.USER_MANAGEMENT)}
          >Back</Button>
        </div>
        <div>
          <SubTitle text={`User Detail`} classes="text-dark" />
        </div>
        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} `}
              onClick={() => setEditModal(true)}
            >Edit Team</Button>
          </Col>
        </Row>
      </div>
      <div className={styles.tableContainer}>
        <Card>
          <Card.Header>
            <div className={styles.cardHeader}>User Detail</div>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={4} sm={12}>
                <div className={styles.cardValue}>
                  <img
                    src={`//www.primetime-tournaments.com/${data?.image_url}`}
                    alt="profile_image"
                    className={styles.profileImage}
                  />
                </div>
              </Col>
              <Col md={8} sm={12}>
                <Row>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col md={6} sm={12}>Name</Col>
                      <Col md={6} sm={12}>{data?.name}</Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col md={6} sm={12}>Role</Col>
                      <Col md={6} sm={12}>{data?.user_type}</Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col md={6} sm={12}>Email</Col>
                      <Col md={6} sm={12}>{data?.email}</Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default UserDetailScreen;
