import React, { useState, useEffect } from 'react';

import styles from "./styles/Dashboard.module.scss"

import { SubTitle } from '../../Components/CustomComponents';
import { Row, Col } from 'react-bootstrap';
// import MatchCard from '../../Components/Cards/MatchCard';
// import TournamentCard from '../../Components/Cards/TournamentCard';
import { ROUTES } from './constants';
import DashboardCard from '../../Components/Cards/DashboardCard';
import { CiUser } from "react-icons/ci";
import { RiParentFill } from "react-icons/ri";
import { FaUserNurse } from "react-icons/fa6";
import { GiBabyfootPlayers } from "react-icons/gi";
import { TbTournament, TbUsersGroup } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { GiTeamDowngrade } from "react-icons/gi";
import { getPlayerList } from '../../services/player';
import { getUsers } from '../../services/UserService';
import { getTournaments } from '../../services/TournamentService';
import { getTeamsList } from '../../services/Team';
import { useQuery } from "react-query";
import { ErrorToast } from "../../Hooks/useToast.js";



export default function Dashboard({ setPage }) {
    const [coaches, setCoaches] = useState([]);
    const [viewers, setViewers] = useState([]);
    const [tourDirectors, setTourDirectors] = useState(0);
    const [playersData, setPlayersData] = useState(0);
    const [tournamentsData, setTournamentsData] = useState(0);
    const [teamsData, setTeamsData] = useState(0);

    const handleViewLiveMatches = () => {
        setPage(ROUTES.LIVE_MATCHES)
    }

    const handleViewTournament = () => {
        setPage(ROUTES.TOURNAMENTS)
    }

    //get all players, tournaments, teams, coaches, parents

    const { data: players, error: playerError } = useQuery({
        queryKey: 'players',
        queryFn: getPlayerList,
        select: (data) => data.data.links.total_records,
        onError: (error) => {
            ErrorToast(error)
        }
    });
    const { data: users, error: userError } = useQuery({
        queryKey: 'users',
        queryFn: getUsers,
        select: (data) => data.data,
        onError: (error) => {
            ErrorToast(error)
        }
    });
    const { data: tournaments, error: tournamentError } = useQuery({
        queryKey: 'tournaments',
        queryFn: getTournaments,
        select: (data) => data.data.links.total_records,
        onError: (error) => {
            ErrorToast(error)
        }
    });
    const { data: teams, error: teamError } = useQuery({
        queryKey: 'teams',
        queryFn: getTeamsList,
        select: (data) => data.data.links.total_records,
        onError: (error) => {
            ErrorToast(error)
        }
    });

    useEffect(() => {
        if (players) {
            setPlayersData(players)
        }
        if (users) {
            setCoaches(users.data.filter(user => user.user_type === 'COACH'))
            setViewers(users.data.filter(user => user.user_type === 'VIEWER'))
            setTourDirectors(users.data.filter(user => user.user_type === 'ADMIN'))
        }
        if (tournaments) {
            setTournamentsData(tournaments)
        }
        if (teams) {
            setTeamsData(teams)
        }
    }, [players, users, tournaments, teams])


    return (
        <div className={styles.Dashboard}>

            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    <SubTitle
                        text={'Dashboard'}
                    />
                </div>
            </div>

            <Row
                className={`${styles.matchRow} w-100 mt-2 mb-5`}
            >
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#860303'}
                        bg2={'#ff0000'}
                        icon={<FaUserNurse color='#860303' />}
                        number={coaches.length}
                        text={'Coaches'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#0a4caa'}
                        bg2={'#3d87f4'}
                        icon={<FaUsers color='#3d87f4' />}
                        number={viewers.length}
                        text={'Users'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#093e22'}
                        bg2={'#4eba33'}
                        icon={<GiBabyfootPlayers color='#4eba33' />}
                        number={playersData}
                        text={'Players'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#e63e19'}
                        bg2={'#f5a51a'}
                        icon={<TbTournament color='#f5a51a' />}
                        number={tournamentsData}
                        text={'Tournaments'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#e63e19'}
                        bg2={'#f5a51a'}
                        icon={<TbUsersGroup color='#f5a51a' />}
                        number={teamsData}
                        text={'Teams'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#0a4caa'}
                        bg2={'#3d87f4'}
                        icon={<GiTeamDowngrade color='#3d87f4' />}
                        number={tourDirectors.length}
                        text={'Admins'}
                    />
                </Col>
            </Row>

        </div>
    )
}

