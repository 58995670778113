import React, { useEffect, useState } from "react";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/notification.module.css";
import { FaCircleXmark } from "react-icons/fa6";
import { Col, Row, Form } from "react-bootstrap";
import { Input, Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import { useForm, Controller } from "react-hook-form";
import styles from "../../Pages/TournamentManagement/index.module.css";
import "./styles/AddTournament.css";
import { refundTeam } from "../../services/generalService";
import Toast from "../../Hooks/useToast";

const RefundPaymentModal = ({ teams, handleAddModalClose, data }) => {
  const [selectedData, setSelectedData] = useState();
  const handleTeamSelection = (e) => {
    setSelectedData(e?.target?.value);
  };
  const [allteams, setAllTeams] = useState([]);

  useEffect(() => {
    RestructureData();
  }, [teams]);

  const RestructureData = () => {
    const res = teams?.map((dt) => ({
      value: dt?.registration_slug,
      label: dt?.name,
    }));
    setAllTeams(res);
  };
  const deleteData = async (id) => {
    let data = {
      registration_slug: selectedData,
    };
    try {
      const res = await refundTeam(data);
      Toast(res?.data?.message, "success");
    } catch (error) {
      Toast(error?.response?.data?.message, "error");
    }
  };
  return (
    <div>
      <ModalContainer handleClose={handleAddModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0"> Refund </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleAddModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100" style={{ cursor: "pointer" }}>
            <div className="mb-5">
              <Row className="mb-3">
                <Col md={12}>
                  <p className="m-0" style={{ color: "#0AA7FF" }}>
                    TEAM
                  </p>

                  <SelectFieldComponent
                    optionsList={allteams}
                    onSelectChange={handleTeamSelection}
                  />
                </Col>
              </Row>
            </div>

            <Row>
              <Col md={12}>
                <Button
                  classes={`${cssStyles.cardBtn} mt-4 `}
                  text={"Refund"}
                  onClick={deleteData}
                ></Button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default RefundPaymentModal;
