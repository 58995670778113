import React, { useState } from "react";

import { ROUTES } from "./Route";
import Login from "../Account/Login";
import TeamManagementScreen from "./TeamManagementScreen";
import TeamDetailManagementScreen from "./TeamDetailManagementScreen";

export default function TeamManagement() {
  const [data, setData] = useState({
    page: ROUTES.TEAM_MANAGEMENT,
    teamId: '',
    details: {}
  })
  
  const setPage = (route, data = {}) => {
    setData({
      page: route,
      teamId: data.teamId ?? '',
      details: data.details ?? {}
    })
  }

  switch (data.page) {
    case ROUTES.TEAM_MANAGEMENT:
      return <TeamManagementScreen setPage={setPage} />;
    case ROUTES.TEAM_DETAIL:
      return <TeamDetailManagementScreen setPage={setPage} teamId={data.teamId} details={data.details}/>;
    default:
      return <Login />;
  }
}
