import React from "react";
import { Col, Row } from "react-bootstrap";
import BorderedCard from "./BorderedCard";
import { formateTTime, formatedDate } from "../../constants";

const RegistrationCard = ({ data }) => {
  const heading = {
    fontSize: 22,
    color: '#0AA7FF',
  };

  return (
    <BorderedCard classes="h-100">
      <div
        style={{
          backgroundColor: "#0D0D1C",
          borderRadius: "22px",
          width: "100%",
          height: "100%",
        }}
      >
        <Row className="h-100">
          <Col
            className="text-center text-white justify-content-center d-flex flex-column align-items-center"
            lg={6}
            md={3}
            xs={6}
            style={{
              borderBottom: "1px solid #2D2D2D",
              borderRight: "1px solid #2d2d2d",
            }}
          >
            <h4 style={heading}>${data?.registration_fee}</h4>

            <p>Registration Fee</p>
          </Col>
          <Col
            className="text-center text-white justify-content-center d-flex flex-column align-items-center"
            lg={6}
            md={3}
            xs={6}
            style={{ borderBottom: "1px solid #2D2D2D" }}
          >
            <h4 style={heading}>{formatedDate(data?.start_date)}</h4>
            <p>Date</p>
          </Col>

          <Col
            className="text-center text-white justify-content-center d-flex flex-column align-items-center"
            lg={6}
            md={3}
            xs={6}
            style={{ borderRight: "1px solid #2d2d2d" }}
          >
            <h4 style={heading}>${data?.ticket_price}</h4>
            <p>Ticket Price</p>
          </Col>
          <Col
            className="text-center text-white justify-content-center d-flex flex-column align-items-center"
            lg={6}
            md={3}
            xs={6}
          >
            <h4 style={heading}>{data?.sports_type}</h4>
            <p>Sport</p>
          </Col>
        </Row>
      </div>
    </BorderedCard>
  );
};

export default RegistrationCard;
