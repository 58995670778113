import api from ".";
import { apiUrl } from "../constants";

export const getUsers = (data) => {
    let url = `${apiUrl.api}/${apiUrl.role}/${apiUrl.allUsers}`;
    url = `${url}${data.page ? `?page=${data.page}` : ''}${data.limit ? `&limit=${data.limit}` : ''}${data.search ? `&search=${data.search}` : ''}`;
    return api.get(url);
}
export const getUserById = (id) =>
    api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.allUsers}/${id}`);

export const createUser = (data) =>
    api.post(`${apiUrl.api}/${apiUrl.role}/${apiUrl.allUsers}`, data);

export const updateUser = (id, data) =>
    api.put(`${apiUrl.api}/${apiUrl.role}/${apiUrl.allUsers}/${id}`, data);

export const deleteUser = (id) =>
    api.delete(`${apiUrl.api}/${apiUrl.role}/${apiUrl.allUsers}/${id}`);
