import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import styles from "./styles/user_management.module.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import Toast from "../../Hooks/useToast";
import EditUserModal from "../../Components/Modals/EditUserModal";
import EditTeamModal from "../../Components/Modals/EditTeamModal";
import { FaEye, FaBackward, FaForward, FaRegEdit } from "react-icons/fa";
import { Col, Row, Stack } from "react-bootstrap";
import { IoCaretForwardSharp, IoCaretBackSharp } from "react-icons/io5";
import { ROUTES } from "./Route";
import { getTeamsRegistered } from "../../services/Team";

const TeamManagementScreen = ({ setPage }) => {
  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [loader, setLoader] = useState(false);
  const [paginationDetail, setPaginationDetail] = useState(null);
  const [teamPage, setTeamPage] = useState(1);
  const [search, setSearch] = useState("");

  const GetTeamsRegistered = useCallback(async () => {
    if (loader) return;
    setLoader(true);
    try {
      const params = { page: teamPage, limit: 10, search: search.trim() || "" };
      const result = await getTeamsRegistered(params);
      setData(result?.data?.data);
      setPaginationDetail(result?.data?.links);
      setIndicationMessage("");
    } catch (e) {
      Toast(e?.response?.message || "Error loading teams", "error");
    } finally {
      setLoader(false);
    }
  }, [teamPage, search]);

  const debouncedGetTeamsRegistered = useCallback(
    debounce(() => {
      GetTeamsRegistered();
    }, 1000),
    [GetTeamsRegistered]
  );

  useEffect(() => {
    debouncedGetTeamsRegistered();
    return debouncedGetTeamsRegistered.cancel;
  }, [search, debouncedGetTeamsRegistered]);

  const handleViewTeam = (rowData) => {
    setPage(ROUTES.TEAM_DETAIL, { teamId: rowData.slug, details: rowData });
  };

  function indication() {
    return indicationMessage;
  }
  
  const handleBackPagination = () => {
    if (paginationDetail?.current > 1) {
      setTeamPage((prev) => prev - 1);
    }
  };
  
  const handleForwardPagination = () => {
    if (paginationDetail?.current < paginationDetail?.total) {
      setTeamPage((prev) => prev + 1);
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: { width: "200px" },
    },
    {
      dataField: "coach_image_url",
      text: "Coach Details",
      sort: true,
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.coach_name && (
          <div className="d-flex align-items-center">
            <img
              src={`//www.primetime-tournaments.com/${row?.coach_image_url}`}
              alt="Coach"
              style={{
                height: "40px",
                maxHeight: "50px",
                borderRadius: "50%",
                margin: "10px",
                padding: "5px",
              }}
            />
            <span>{row?.coach_name}</span>
          </div>
        )
      ),
    },
    {
      dataField: "age_group",
      text: "Age Group",
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.age && (
          <div className="d-flex align-items-center">
            <span>{row.age.title}</span>
          </div>
        )
      ),
    },
    {
      dataField: "members",
      text: "Members",
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.members && (
          <div className="d-flex align-items-center">
            <span>{row.members.length}</span>
          </div>
        )
      ),
    },
    {
      dataField: "sports_type",
      text: "Sport",
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.members && (
          <div className="d-flex align-items-center">
            <span>{row.sports_type}</span>
          </div>
        )
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.slug && (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className={`${styles.btnBg} d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2`}
              role="button"
              onClick={() => handleViewTeam(row)}
            >
              <span className="d-flex align-items-center pe-1">
                <FaEye />
              </span>
              <span>View</span>
            </div>
          </div>
        )
      ),
    },
  ];
  
  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div className={`${styles.titleContainer} mb-1`}>
          <SubTitle text="Team Management" classes="text-dark"/>
        </div>
      </div>
      <div className={styles.searchContainer}>
        <Row>
          <Col>
            <Stack direction="horizontal" gap={3}>
              <input
                type="text"
                value={search}
                placeholder="Search for Teams..."
                className="form-control"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Stack>
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table data={data} columns={columns} indication={indication} />
        <div className="pagination" style={{ fontSize: "15px", color: "black", display: "flex", justifyContent: "end", alignItems: "center", marginRight: "12px" }}>
          <FaBackward
            cursor="pointer"
            color={paginationDetail?.current === 1 ? "gray" : "blue"}
            size={20}
            onClick={() => setTeamPage(1)}
          />
          <IoCaretBackSharp
            cursor="pointer"
            color={paginationDetail?.current === 1 ? "gray" : "blue"}
            size={20}
            onClick={handleBackPagination}
          />
          <span>{paginationDetail?.current}/{paginationDetail?.total}</span>
          <IoCaretForwardSharp
            cursor="pointer"
            color={paginationDetail?.current === paginationDetail?.total ? "gray" : "blue"}
            size={20}
            onClick={handleForwardPagination}
          />
          <FaForward
            cursor="pointer"
            color={paginationDetail?.current === paginationDetail?.total ? "gray" : "blue"}
            size={20}
            onClick={() => setTeamPage(paginationDetail?.total)}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamManagementScreen;
