import React, { useEffect, useState } from "react";
import styles from "./styles/user_management.module.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Toast from "../../Hooks/useToast";
import { getTeamsDetailBySlug, removePlayerBySlug } from "../../services/Team";
import { useParams } from "react-router-dom";
import { IoCaretForwardSharp } from "react-icons/io5";
import { IoIosRemoveCircle } from "react-icons/io";
import { Button, Col, Row, Card, Stack } from "react-bootstrap";
import { IoCaretBackSharp } from "react-icons/io5";
import { FaBackward } from "react-icons/fa";
import { FaForward } from "react-icons/fa";
import AddPlayerModal from "../../Components/Modals/AddPlayerModal";
import { UpdateTeam } from "../../services/Team";
import EditTeamModal from "../../Components/Modals/EditTeamModal";
import { ROUTES } from "./Route";
import { set } from "lodash";

const TeamDetailManagementScreen = ({ setPage, teamId, details }) => {
  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState("");
  const [search, setSearch] = useState("");
  const [editModal, setEditModal] = useState(false)
  const [paginationDetail, setPaginationDetail] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addPlayerModal, setAddPlayerModal] = useState(false);
  const [teamPage, setTeamPage] = useState(1);


  const updateData = () => {
    const filteredData = data.filter((row) => {
      return row.name.toLowerCase().includes(search.toLowerCase());
    });
    if (filteredData.length === 0) {
      Toast("No data found", "error");
      GetTeamsById();
    } else {
      setData(filteredData);
      Toast("Data Updated", "success");
    }
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setRowData("");
  };

  const handleAddPlayerModalClose = () => {
    setAddPlayerModal(false);
    setRowData("");
  };

  const handleAddUser = () => {
    setAddPlayerModal(true);
  };

  function indication() {
    return indicationMessage;
  }

  const EditTeam = async (data) => {
    if (data !== null || data !== undefined) {

        const res = await UpdateTeam(teamId, data);

        if (res.status === 200) {
            Toast(res?.data?.message, "success");
            setEditModal(false);
        } else {
            console.log('Error editing Team');
        }
    }
  }

  const handleEditModalClose = () => {
    setEditModal(!editModal)
  };

  const GetTeamsById = async () => {
    if (search) {
      setSearch("");
    }
    try {
      let result = await getTeamsDetailBySlug(teamId);
      setData(result?.data?.data);
      setPaginationDetail(result?.data?.links);
      setIndicationMessage("");
      Toast(result?.data?.message, "success");
    } catch (e) {
      Toast(e?.response?.message, "error");
    }
  };

  const RemovePlayer = async () => {
    try {
      const result = await removePlayerBySlug(teamId, rowData?.slug);
      GetTeamsById();
      setDeleteModal(false);
      Toast(result?.data?.message, "success");
    } catch (e) {
      Toast(e?.response?.data?.message, "error");
    }
  };

  const handleBackPagination = () => {
    if (paginationDetail?.current != 1) {
      setTeamPage(teamPage - 1);
    }
  };
  const handleForwardPagination = () => {
    if (paginationDetail?.current != paginationDetail?.total) {
      setTeamPage(teamPage + 1);
    }
  };

  useEffect(() => {
    GetTeamsById();
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },

    {
      dataField: "coach_image_url",
      text: "Image",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        if (row.image_url) {
          return (
            <div className="d-flex align-items-center">
              <img
                src={`//www.primetime-tournaments.com/${row?.image_url}`}
                style={{
                  height: "40px",
                  maxHeight: "50px",
                  borderRadius: "50%",
                  margin: "10px",
                  padding: "5px",
                }}
              />
            </div>
          );
        }
      },
    },
    {
      dataField: "slug",
      text: "Remove Player",
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => {
              handleDeleteModalOpen();
              setRowData(row);
            }}
          >
            <IoIosRemoveCircle color="red" size={20} cursor={"pointer"} />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          deletetxt={"Delete"}
          data={rowData}
          id={teamId}
          onDelete={RemovePlayer}
        />
      ) : (
        ""
      )}

      {addPlayerModal ? (
        <AddPlayerModal
          handleAddPlayerModalClose={handleAddPlayerModalClose}
          data={rowData}
          teamSlug={teamId}
          sportsType={details?.sports_type}
          agegroup={details?.age.slug}
          GetTeamsById={GetTeamsById}
        />
      ) : (
        ""
      )}
      {editModal ? (
        <EditTeamModal
            show={editModal}
            handleEditModalClose={handleEditModalClose}
            onEdit={EditTeam}
            data={details}
            edittxt={"Save Changes"}
        />
      ) : (
          ""
      )}
      <Row className={styles.headerContainer}>
        <div className="mb-2">
          <Stack direction="horizontal" gap={3}>
            <button onClick={() => setPage(ROUTES.TEAM_MANAGEMENT)} className={`${styles.backButton} mt-2`} >Back</button>
            <button onClick={() => setEditModal(true)} className={`${styles.editBadge} mt-2`} >Edit</button>
            <button onClick={handleAddUser} className={`${styles.deleteBadge} mt-2`} >Add Player</button>
            {/* <button onClick={() => setDeleteModal(true)} className={`${styles.deleteBadge} mt-2`} >DELETE</button> */}
          </Stack>
        </div>
        <Col sm={12} className="text-right">
          <SubTitle text={`Team Detail Management`} classes="text-dark" />
        </Col>
      </Row>

      <Row className="m-2">
        <Col sm={12} className="d-flex justify-content-center">
          <Card className="mb-4">
              <Card.Body>
                  <Card.Title>{details.name}</Card.Title>
                  <Row>
                      <Col md={6}>
                          <Card.Text>
                              <strong>Coach:</strong> {details.coach_name}
                          </Card.Text>
                      </Col>
                      <Col md={6}>
                        <Card.Text>
                          <strong>Sports Type:</strong> {details.sports_type}
                        </Card.Text>
                      </Col>
                      <Col md={6}>
                        <Card.Text>
                            <strong>Class:</strong> {details.class}
                        </Card.Text>
                      </Col>
                      <Col md={6}>
                        <Card.Text>
                            <strong>Age Group:</strong> {details.age.title}
                        </Card.Text>
                      </Col>
                      <Col md={6}>
                      </Col>
                  </Row>
                  
              </Card.Body>
          </Card>
        </Col>
        <Col md={9} sm={12}>
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search for Players..."
            className={`${styles.searchInput} form-control`}
            /> 
        </Col>
        <Col md={2} sm={12} className="d-flex justify-content-end">
          <Row>
            <Col md={6} sm={12}>
              <Button
              onClick={updateData}
              className={styles.search}
            >Search</Button>
            </Col>
            <Col md={6} sm={12}>
              <Button
              onClick={GetTeamsById}
              className="btn btn-danger"
            >Reset</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className={styles.mainTable}>
        <Table data={data} columns={columns} indication={indication} />
        <div
          className="pagination"
          style={{
            fontSize: "15px",
            color: "white",
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            marginRight: "12px",
          }}
        >
          <FaBackward
            cursor={"pointer"}
            color={paginationDetail?.current == 1 ? "gray" : "white"}
            size={20}
            onClick={() => setPage(1)}
          />
          <IoCaretBackSharp
            cursor={"pointer"}
            color={paginationDetail?.current == 1 ? "gray" : "white"}
            size={20}
            onClick={handleBackPagination}
          />
          <span>
            {paginationDetail?.current}/{paginationDetail?.total}
          </span>
          <IoCaretForwardSharp
            cursor={"pointer"}
            color={
              paginationDetail?.current === paginationDetail?.total
                ? "gray"
                : "white"
            }
            size={20}
            onClick={handleForwardPagination}
          />
          <FaForward
            cursor={"pointer"}
            color={
              paginationDetail?.current === paginationDetail?.total
                ? "gray"
                : "white"
            }
            size={20}
            onClick={() => setPage(paginationDetail?.total)}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamDetailManagementScreen;
