import { apiUrl } from "../constants"
import api from "./Multipart"

export const deleteTournament = (slug) => 
    api.delete(`${apiUrl.api}/${apiUrl.role}/${apiUrl.tournament}/${slug}`)

export const editTournament = (slug, data) =>
    api.put(`${apiUrl.api}/${apiUrl.role}/${apiUrl.tournament}/${slug}`, data)

export const getTournaments = (data) =>
    api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.tournament}`, data);
