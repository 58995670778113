import React from 'react';
// import styles from './TreeComponent2.css';

const BracketTable = () => {

  return (
    <div className="bracket">
      <svg width="2000" height="1600" xmlns="http://www.w3.org/2000/svg">
          <text x="20" y="30" font-size="16" font-weight="bold">Winner's Bracket</text>
      
          <rect x="60" y="310" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="70" y="330" font-size="12">Team 5</text>
          <rect x="60" y="390" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="70" y="410" font-size="12">Team 6</text>
          <line x1="180" y1="325" x2="210" y2="325" stroke="black" />
          <line x1="180" y1="405" x2="210" y2="405" stroke="black" />
          <line x1="210" y1="325" x2="210" y2="405" stroke="black" />
          {/* end of round w3 */}
      
          <rect x="210" y="25" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="220" y="45" font-size="12">Team 1</text>
          <rect x="210" y="190" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="220" y="210" font-size="12">Team 2</text>
          <line x1="330" y1="40" x2="360" y2="40" stroke="black" />
          <line x1="330" y1="205" x2="360" y2="205" stroke="black" />
          <line x1="360" y1="40" x2="360" y2="205" stroke="black" />
      
          <rect x="210" y="345" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="220" y="365" font-size="12">Team 3</text>
          <rect x="210" y="510" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="220" y="530" font-size="12">Team 4</text>
          <line x1="330" y1="360" x2="360" y2="360" stroke="black" />
          <line x1="330" y1="525" x2="360" y2="525" stroke="black" />
          <line x1="360" y1="360" x2="360" y2="525" stroke="black" />
          {/* end of round w4 */}
      
          <rect x="360" y="95" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="370" y="115" font-size="12">Team 1</text>
          <rect x="360" y="425" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="370" y="445" font-size="12">Team 2</text>
          <line x1="480" y1="100" x2="660" y2="100" stroke="black" />
          <line x1="480" y1="440" x2="660" y2="440" stroke="black" />
          <line x1="660" y1="100" x2="660" y2="440" stroke="black" />
          {/* end of round w5 */}
      
          <rect x="660" y="270" width="120" height="30" fill="lightblue" stroke="black" />
          <text x="680" y="290" font-size="12">Team 1</text>
      
          <text x="300" y="610" font-size="16" font-weight="bold">Loser's Bracket</text>
      
          <rect x="360" y="630" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="370" y="650" font-size="12">Loser 1</text>
          <rect x="360" y="705" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="370" y="725" font-size="12">Loser 2</text>
          <line x1="480" y1="645" x2="510" y2="645" stroke="black" />
          <line x1="480" y1="720" x2="510" y2="720" stroke="black" />
          <line x1="510" y1="645" x2="510" y2="720" stroke="black" />
          {/* end of round l5 */}
      
          <rect x="510" y="660" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="530" y="680" font-size="12">Loser 1</text>
          <rect x="510" y="940" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="530" y="960" font-size="12">Loser 2</text>
          <line x1="630" y1="670" x2="660" y2="670" stroke="black" />
          <line x1="630" y1="955" x2="660" y2="955" stroke="black" />
          <line x1="660" y1="670" x2="660" y2="955" stroke="black" />
          {/* end of round l6 */}
      
          <rect x="660" y="615" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="680" y="635" font-size="12">Loser 1</text>
          <rect x="660" y="750" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="680" y="770" font-size="12">Loser 2</text>
          <line x1="780" y1="630" x2="810" y2="630" stroke="black" />
          <line x1="780" y1="765" x2="810" y2="765" stroke="black" />
          <line x1="810" y1="630" x2="810" y2="765" stroke="black" />
          {/* end of round l7 */}
      
          <rect x="810" y="680" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="830" y="700" font-size="12">Loser 1</text>
          {/* end of round l8 */}
      
          <line x1="780" y1="285" x2="1010" y2="285" stroke="black" />
          <line x1="930" y1="690" x2="1010" y2="690" stroke="black" />
          <line x1="1010" y1="285" x2="1010" y2="690" stroke="black" />
          
          <rect x="1010" y="500" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="1030" y="520" font-size="12">winner 1</text>
          <rect x="1010" y="780" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="1030" y="800" font-size="12">loser 2</text>
          <line x1="1130" y1="505" x2="1160" y2="505" stroke="black" />
          <line x1="1130" y1="800" x2="1160" y2="800" stroke="black" />
          <line x1="1160" y1="505" x2="1160" y2="800" stroke="black" />
          {/* end of round l9 */}
      
          <rect x="1160" y="630" width="120" height="30" fill="lightyellow" stroke="black" />
          <text x="1180" y="650" font-size="12">winner 1</text>
          {/* end of round l10 */}
      </svg>
    </div>
  );
};

export default BracketTable;