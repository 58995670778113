import React from 'react';

import styles from "./styles/AccountLayout.module.scss"

import { Container, Row, Col } from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";


import { ShadowContainer } from '../CustomComponents';

export default function AccountLayout({
    hasBackButton = false,
    handleBackButton = () => { },
    children
}) {
    return (
        <div className={styles.AccountLayout} style={{ backgroundImage: `url(/images/diamond.jpeg)` }}>
            <Container className={styles.sectionContainer}>

                <Row className={styles.row}>
                    <Col
                        md={6}
                        className={styles.formContainer}
                    >
                        <div className={styles.form}>
                            {hasBackButton ? <div className={styles.backButtonContainer} onClick={handleBackButton}>
                                <IoIosArrowBack />
                            </div> : ""
                            }
                            {children}
                        </div>
                    </Col>
                    <Col
                        md={6}
                        className={`${styles.mediaContainer}`}
                    >
                        <img src='/images/slide-diamond2.png' alt='Logo Assets' className={styles.image} style={{width:'100px', height:'70px'}} />
                        <img src='/images/logo.png' alt="Logo" className={`${styles.image} ${styles.logo}`} />
                    </Col>

                </Row>
            </Container>

        </div>
    )
}