import React, { useState } from "react";
import { ROUTES } from "./Route";
import {
  BackButton,
  Button,
  CustomInput,
  SubTitle,
  Select,
  Input
} from "../../Components/CustomComponents";
import { Col, Row, Form } from "react-bootstrap";
import _ from "lodash";
import { useForm, Controller } from "react-hook-form";
import Toast from "../../Hooks/useToast";
import { createUser } from "../../services/UserService";

const CreateUserScreen = ({ setPage, setSlug }) => {

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirm_password: "",
    user_type: "",
  })

  const handleInputChange = (name, value) => {
    setData(prev => {
        return {
            ...prev,
            [name]: value
        }
    })
  }

  const handleBackButtonClick = () => {
    setPage(ROUTES.USER_MANAGEMENT);
  };

  const [isSubmitted, setIsSubmitted] = useState(false)

  const userList = [
    { label: 'Admin', value:'ADMIN' },
    { label: 'Coach', value:'COACH' },
    { label: 'Player', value:'PLAYER' },
    { label: 'Viewer', value:'VIEWER' },
  ];
  const handleCreateUser = async () => {
    if (!data?.firstname) return Toast('First name is required', 'error', false)
      if (!data?.lastname) return Toast('Last is required', 'error', false)
      if (!data?.email) return Toast('Email is required', 'error', false)
      if (!data?.user_type) return Toast('User Type is required', 'error', false)
      if (!data?.password) return Toast('Password is required', 'error', false)
      if (!data?.confirm_password) return Toast('Confirm password is required', 'error', false)
      if (data?.confirm_password !== data.password) return Toast("Confirm password doesn't match with password", 'error', false)
    const formData = new FormData();
    formData.append("firstname", data?.firstname);
    formData.append("lastname", data?.lastname);
    formData.append("email", data?.email);
    formData.append("password", data?.password);
    formData.append("confirm_password", data?.confirm_password);
    formData.append("user_type", data?.user_type);

    try {
      const response = await createUser(formData);
      Toast(response?.data?.message, "success");
      setPage(ROUTES.USER_MANAGEMENT)
    } catch (error) {
      Toast(error?.response?.data?.message, "error");
    }
  };
  return (
    <div className="w-100">
      <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />
      <SubTitle text={"Create User"} classes="text-dark" />
      <Row className="w-100">
        <Col>
          <Row>
            <Col
              md={6}
            >
              <Input
                name="firstname"
                value={data.firstname}
                onChange={handleInputChange}
                placeholder="First name"
              />
            </Col>
            <Col
              md={6}
            >
              <Input
                name="lastname"
                value={data.lastname}
                onChange={handleInputChange}
                placeholder="Last name"
              />
            </Col>
          </Row>
          <Input
            name="email"
            value={data.email}
            onChange={handleInputChange}
            placeholder="Email Address"
          />
          <Input
            type="password"
            name="password"
            value={data.password}
            onChange={handleInputChange}
            classes="mt-2 mb-2"
            placeholder="Password"
          />
          <Input
            classes="mt-2 mb-2"
            type="password"
            name="confirm_password"
            value={data.confirm_password}
            onChange={handleInputChange}
            placeholder="Confirm Password"
          />


          <Select
            name="user_type"
            value={data.user_type}
            onChange={handleInputChange}
            options={userList}
            placeholder="Select User Role"
            classes="mt-2 mb-2"
          />

          <Button
            isSubmitted={isSubmitted}
            onClick={handleCreateUser}
            text='Register'
            style={{ border: "2px solid #848A93" }}
            classes="mt-4 mb-2"
          />
      </Col>
    </Row>
    </div>
  );
};

export default CreateUserScreen;
