import React, { useState } from "react";
import cssStyles from "./styles/notification.module.css";
import { FaCircleXmark } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
import { refundTeam } from "../../services/generalService";
import Toast from "../../Hooks/useToast";
import { Col, Row, Modal, Button, Form, Alert } from "react-bootstrap";
import _, { set } from "lodash";

const EditUserModal = ({ show, handleEditModalClose, onEdit, data, edittxt}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showUserAlert, setShowUserAlert] = useState(false);
  const [editData, setEditData] = useState({
      firstname: data.firstname ?? "",
      lastname: data.lastname ?? "",
      email: data.email ?? "",
      password: data.password ?? "",
      image_url: data.image_url ?? "",
      user_type: data.user_type ?? "",
  });

  const userList = [
    { label: 'Admin', value:'ADMIN' },
    { label: 'Coach', value:'COACH' },
    { label: 'Player', value:'PLAYER' },
    { label: 'Viewer', value:'VIEWER' },
  ];

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState();
  
    const handleInputChange = (name, value) => {
        if (isSubmitted) return;
        setEditData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        setError(prev => {
            return {
                ...prev,
                [name]: !value ? `${name} should not be empty` : ""
            }
        })
    }

    const handleImageChange = (name, value) => {
        if (isSubmitted) return;
        setEditData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handlePasswordChange = (e) => {
      const { name, value } = e.target;
      if (name === 'password') {
          setPassword(value);
      } else if (name === 'confirmPassword') {
          setConfirmPassword(value);
      }
      setShowAlert(false);
    };

    const handleSubmit = () => {
      if (password !== confirmPassword) {
        setShowUserAlert(true);
      } else if (editData.user_type === "" || editData.user_type === null || editData.user_type === undefined){
        return;
      } else {
        setEditData(prev => {
          return {
            ...prev,
            password: password
          }
        });
        setIsSubmitted(true);
        onEdit(editData);
        handleEditModalClose();
      }
    };

  return (
    <Modal size="xl" show={show} onHide={handleEditModalClose}>
      <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={8} sm={12}>
            <Row>
                <Form.Group className='col-sm-12 col-md-6'>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="input"
                        value={editData.firstname}
                        onChange={(e) => handleInputChange('firstname',e.target.value)}
                    />
                </Form.Group>
                <Form.Group className='col-sm-12 col-md-6'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="input"
                        value={editData.lastname}
                        onChange={(e) => handleInputChange('lastname',e.target.value)}
                    />
                </Form.Group>
                <Form.Group className='col-sm-12 col-md-6'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="input"
                        value={editData.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                    />
                </Form.Group>
                <Form.Group className='col-sm-12 col-md-6'>
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                        as="select"
                        value={editData.user_type}
                        onChange={(e) => handleInputChange('user_type', e.target.value)}
                    >
                        <option value="">Select User</option>
                        {userList.map((role) => (
                            <option key={role.value} value={role.value}>
                                {role.label}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group className='col-sm-12 col-md-6'>
                    <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="*********"
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                  <small>*at least 8 characters, a number, and special character</small>
                </Form.Group>
                <Form.Group className='col-sm-12 col-md-6'>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                      type="password"
                      placeholder="*********"
                      name="confirmPassword"  
                      value={confirmPassword}
                      onChange={handlePasswordChange}
                  />
                </Form.Group>
            </Row>
          </Col>
          <Col md={4} sm={12}>
            <Form.Group className='col-sm-12 mb-1'>
                {editData.image_url && typeof editData.image_url === 'object' ? (
                    <img src={URL.createObjectURL(editData.image_url)} style={{width: "50%", height: "50%"}} alt="User Image" />
                ) : (
                    <img src={`//www.primetime-tournaments.com/${editData.image_url}`} style={{width: "50%", height: "50%"}} alt="User Image" />
                )}<br></br>
                <Form.Label>User Image</Form.Label>
                <Form.Control
                    type="file"
                    onChange={(e) => handleImageChange('image_url', e.target.files[0])}
                    accept="*.png,*jpeg,*.jpg,image/*"
                />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
          {showAlert && (
            <Alert variant="danger" className='col-sm-12 col-md-3'>
              Passwords do not match!
            </Alert>
          )}
          {showUserAlert && (
            <Alert variant="danger" className='col-sm-12 col-md-3'>
              User Type is required!
            </Alert>
          )}
          <Button variant="secondary" onClick={handleEditModalClose}>
              Cancel
          </Button>
          <Button variant="primary" onClick={() =>handleSubmit()}>
              {edittxt}
          </Button>
      </Modal.Footer>
  </Modal>
  );
};

export default EditUserModal;
