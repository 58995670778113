import React from 'react';
// import styles from './TreeComponent2.css';

const BracketTable = () => {

  return (
    <div className="bracket">
                <svg width="2000" height="1600" xmlns="http://www.w3.org/2000/svg">
            <text x="20" y="30" font-size="16" font-weight="bold">Winner's Bracket</text>
        
            <rect x="100" y="455" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="110" y="475" font-size="12">Team 11</text>
            <rect x="100" y="495" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="110" y="515" font-size="12">Team 12</text>
            <line x1="220" y1="470" x2="250" y2="470" stroke="black" />
            <line x1="220" y1="505" x2="250" y2="505" stroke="black" />
            <line x1="250" y1="470" x2="250" y2="505" stroke="black" />
            {/* end of round w2 */}
        
            <rect x="250" y="70" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="260" y="90" font-size="12">Team 1</text>
            <rect x="250" y="150" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="260" y="170" font-size="12">Team 2</text>
            <line x1="370" y1="85" x2="400" y2="85" stroke="black" />
            <line x1="370" y1="165" x2="400" y2="165" stroke="black" />
            <line x1="400" y1="85" x2="400" y2="165" stroke="black" />
        
            <rect x="250" y="230" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="260" y="250" font-size="12">Team 3</text>
            <rect x="250" y="310" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="260" y="330" font-size="12">Team 4</text>
            <line x1="370" y1="245" x2="400" y2="245" stroke="black" />
            <line x1="370" y1="325" x2="400" y2="325" stroke="black" />
            <line x1="400" y1="245" x2="400" y2="325" stroke="black" />
        
            <rect x="250" y="390" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="260" y="410" font-size="12">Team 5</text>
            <rect x="250" y="470" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="260" y="490" font-size="12">Team 6</text>
            <line x1="370" y1="405" x2="400" y2="405" stroke="black" />
            <line x1="370" y1="485" x2="400" y2="485" stroke="black" />
            <line x1="400" y1="405" x2="400" y2="485" stroke="black" />
        
            <rect x="250" y="550" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="260" y="570" font-size="12">Team 7</text>
            <rect x="250" y="630" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="260" y="650" font-size="12">Team 8</text>
            <line x1="370" y1="565" x2="400" y2="565" stroke="black" />
            <line x1="370" y1="645" x2="400" y2="645" stroke="black" />
            <line x1="400" y1="565" x2="400" y2="645" stroke="black" />
            {/* end of round w3 */}
        
            <rect x="400" y="105" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="410" y="125" font-size="12">Team 1</text>
            <rect x="400" y="270" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="410" y="290" font-size="12">Team 2</text>
            <line x1="520" y1="120" x2="550" y2="120" stroke="black" />
            <line x1="520" y1="285" x2="550" y2="285" stroke="black" />
            <line x1="550" y1="120" x2="550" y2="285" stroke="black" />
        
            <rect x="400" y="425" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="410" y="445" font-size="12">Team 3</text>
            <rect x="400" y="590" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="410" y="610" font-size="12">Team 4</text>
            <line x1="520" y1="440" x2="550" y2="440" stroke="black" />
            <line x1="520" y1="605" x2="550" y2="605" stroke="black" />
            <line x1="550" y1="440" x2="550" y2="605" stroke="black" />
            {/* end of round w4 */}
        
            <rect x="550" y="175" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="560" y="195" font-size="12">Team 1</text>
            <rect x="550" y="505" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="560" y="525" font-size="12">Team 2</text>
            <line x1="670" y1="180" x2="850" y2="180" stroke="black" />
            <line x1="670" y1="520" x2="850" y2="520" stroke="black" />
            <line x1="850" y1="180" x2="850" y2="520" stroke="black" />
            {/* end of round w5 */}
        
            <rect x="850" y="350" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="870" y="370" font-size="12">Team 1</text>
        
            <text x="180" y="690" font-size="16" font-weight="bold">Loser's Bracket</text>
        
            <rect x="250" y="710" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="260" y="730" font-size="12">Loser 1</text>
            <rect x="250" y="760" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="260" y="780" font-size="12">Loser 2</text>
            <line x1="370" y1="730" x2="400" y2="730" stroke="black" />
            <line x1="370" y1="775" x2="400" y2="775" stroke="black" />
            <line x1="400" y1="730" x2="400" y2="775" stroke="black" />
            {/* end of round l3 */}
        
            <rect x="400" y="740" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="410" y="760" font-size="12">Loser 1</text>
            <rect x="400" y="840" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="410" y="860" font-size="12">Loser 2</text>
            <line x1="520" y1="755" x2="550" y2="755" stroke="black" />
            <line x1="520" y1="855" x2="550" y2="855" stroke="black" />
            <line x1="550" y1="755" x2="550" y2="855" stroke="black" />
        
            <rect x="400" y="940" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="410" y="960" font-size="12">Loser 3</text>
            <rect x="400" y="1040" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="410" y="1060" font-size="12">Loser 4</text>
            <line x1="520" y1="955" x2="550" y2="955" stroke="black" />
            <line x1="520" y1="1055" x2="550" y2="1055" stroke="black" />
            <line x1="550" y1="955" x2="550" y2="1055" stroke="black" />
            {/* end of round l4 */}
            <rect x="550" y="710" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="560" y="730" font-size="12">Loser 1</text>
            <rect x="550" y="785" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="560" y="805" font-size="12">Loser 2</text>
            <line x1="670" y1="725" x2="700" y2="725" stroke="black" />
            <line x1="670" y1="800" x2="700" y2="800" stroke="black" />
            <line x1="700" y1="725" x2="700" y2="800" stroke="black" />
        
            <rect x="550" y="985" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="560" y="1005" font-size="12">Loser 3</text>
            <rect x="550" y="1060" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="560" y="1080" font-size="12">Loser 4</text>
            <line x1="670" y1="1000" x2="700" y2="1000" stroke="black" />
            <line x1="670" y1="1075" x2="700" y2="1075" stroke="black" />
            <line x1="700" y1="1000" x2="700" y2="1075" stroke="black" />
            {/* end of round l5 */}
        
            <rect x="700" y="740" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="720" y="760" font-size="12">Loser 1</text>
            <rect x="700" y="1020" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="720" y="1040" font-size="12">Loser 2</text>
            <line x1="820" y1="750" x2="850" y2="750" stroke="black" />
            <line x1="820" y1="1035" x2="850" y2="1035" stroke="black" />
            <line x1="850" y1="750" x2="850" y2="1035" stroke="black" />
            {/* end of round l6 */}
        
            <rect x="850" y="695" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="870" y="715" font-size="12">Loser 1</text>
            <rect x="850" y="830" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="870" y="850" font-size="12">Loser 2</text>
            <line x1="970" y1="710" x2="1000" y2="710" stroke="black" />
            <line x1="970" y1="845" x2="1000" y2="845" stroke="black" />
            <line x1="1000" y1="710" x2="1000" y2="845" stroke="black" />
            {/* end of round l7 */}
        
            <rect x="1000" y="760" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1020" y="780" font-size="12">Loser 1</text>
            {/* end of round l8 */}
        
            <line x1="970" y1="365" x2="1200" y2="365" stroke="black" />
            <line x1="1120" y1="775" x2="1200" y2="775" stroke="black" />
            <line x1="1200" y1="365" x2="1200" y2="775" stroke="black" />
            
            <rect x="1200" y="580" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1220" y="600" font-size="12">winner 1</text>
            <rect x="1200" y="860" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1220" y="880" font-size="12">loser 2</text>
            <line x1="1320" y1="595" x2="1350" y2="595" stroke="black" />
            <line x1="1320" y1="875" x2="1350" y2="875" stroke="black" />
            <line x1="1350" y1="595" x2="1350" y2="875" stroke="black" />
            {/* end of round l9 */}
        
            <rect x="1350" y="710" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1370" y="730" font-size="12">winner 1</text>
            {/* end of round l10 */}
        </svg>
    </div>
  );
};

export default BracketTable;