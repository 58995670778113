import React from 'react';
import { Button } from '../CustomComponents';
import cssStyles from './styles/index.module.css';
import { Col } from 'react-bootstrap';
import { RiRefund2Line } from "react-icons/ri";

const TeamAgeCard = ({ data, sNo, handleDeleteModalOpen }) => {

  const handleDeleteMemeber = (id) => {
    console.log("changed");

  };

  return (
    // <div style={{ display: 'flex', background: '#222232', borderRadius: '10px', }}>

    <div className='p-2 rounded-4' style={{ background: 'linear-gradient(-1deg, rgb(200, 200, 200) 6%, rgb(157, 157, 157) 51%)', border:'1px solid #8d8d8d', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
      <Col
        md={8}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >

        <span className='p-2' > {sNo + 1} </span>

        <img

          style={{ width: '45px', height: '45px', margin: '0 10px', }}
          src={data?.image_url}
          alt="Team logo"
        />

        <p className=' fw-bold lc-1 m-0'> {data?.name} </p>

      </Col>

      <Col
        md={4}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '2px',
        }} >

        <button className='btn btn-danger' onClick={handleDeleteModalOpen}>
          <RiRefund2Line />
        </button>



      </Col>
    </div >

  )
}

export default TeamAgeCard


