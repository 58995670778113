import React, { useState } from 'react';
import axios from "axios";
import { Col, Row } from 'react-bootstrap';
import TeamAgeCard from '../../Components/Cards/TeamAgeCard';
import DeleteModal from "../../Components/Modals/DeleteModal";
import Toast from '../../Hooks/useToast';
import { refundTeam } from '../../services/generalService';

const TeamsTabContent = ({ teams, getTeams }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowData, setRowData] = useState("");

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setRowData("");
  };

  const deleteData = async (id) => {
    try {
      const res = await refundTeam({'registration_slug': rowData?.id})
      Toast(res?.data?.message, 'success')
      getTeams()
      handleDeleteModalClose()
    } catch (error) {
      Toast(error?.response?.data?.message, "error");
    }
  };

  return (
    <>
      {deleteModal ?
        < DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          deletetxt='Delete'
          data={rowData}
          onDelete={deleteData}
        />
        : ''}
      <Row>
        {teams?.length > 0 ?
          teams?.map((dt, index) => (
            <Col className='p-3  text-light' key={index} xxl={4} md={6} sm={12}  >

              <TeamAgeCard data={dt} sNo={index} handleDeleteModalOpen={() => {
                setRowData({ id: dt?.registration_slug, name: dt?.name });
                handleDeleteModalOpen();
              }} />

            </Col>

          )) : <div className='d-flex justify-content-center m-5 text-dark'>No Teams</div>

        }

      </Row>
    </>
  );
}


export default TeamsTabContent
