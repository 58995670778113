import React, { useMemo, useState } from 'react'
import { Image, Col, Row, Modal, Button, Form } from "react-bootstrap";
import { Input, Select, CustomSelect } from "../../Components/CustomComponents";
import { useMutation, useQueryClient } from 'react-query';
import Upload from "rc-upload"
import _, { set } from 'lodash';
import Toast from "../../Hooks/useToast";


const TeamEditScreen = ({ show, handleEditModalClose, onEdit, data, edittxt }) => {
    const ACCEPT_IMAGE = 'image/png, image/jpeg, image/jpg'

    const [editData, setEditData] = useState({
        name: data.name ?? "",
        class: data.class ?? "",
        sports_type: data.sports_type ?? "",
        image_url: data.image_url ?? "",
        group_type: data.age.slug ?? "",    
    })

    const sports = [
        { name: "Baseball"},
        { name: "Fast Pitch"},
    ];

    const classes = [
        { name: "A"},
        { name: "B"},
        { name: "C"},
        { name: "REC"},
        { name: "Other"},
    ];

    const ageGroups = [
        { name: "U_6"},
        { name: "U_8"},
        { name: "U_10"},
        { name: "U_12"},
        { name: "U_14"},
        { name: "U_16"},
        { name: "U_18"}
    ];

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState();

    const handleInputChange = (name, value) => {
        if (isSubmitted) return;
        setEditData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        setError(prev => {
            return {
                ...prev,
                [name]: !value ? `${name} should not be empty` : ""
            }
        })
    }

    const handleImageChange = (name, value) => {
        if (isSubmitted) return;
        setEditData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    return (
        <Modal size="xl" show={show} onHide={handleEditModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Team</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={8} sm={12}>
                        <Row>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Team Name</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={editData.name}
                                    onChange={(e) => handleInputChange('name',e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Sport</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={editData.sports_type}
                                    onChange={(e) => handleInputChange('sports_type', e.target.value)}
                                >
                                    <option value="">Select Sport</option>
                                    {sports.map((sport) => (
                                        <option key={sport.name} value={sport.name}>
                                            {sport.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Class</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={editData.class}
                                    onChange={(e) => handleInputChange('class', e.target.value)}
                                >
                                <option value="">Select Class</option>
                                    {classes.map((classItem) => (
                                        <option key={classItem.name} value={classItem.name}>
                                            {classItem.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Age Group</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={editData.group_type}
                                    onChange={(e) => handleInputChange('group_type', e.target.value)}
                                >
                                <option value="">Select Age Group</option>
                                    {ageGroups.map((ageItem) => (
                                        <option key={ageItem.name} value={ageItem.name}>
                                            {ageItem.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group className='col-sm-12 mb-1'>
                            {editData.image_url && typeof editData.image_url === 'object' ? (
                                <img src={URL.createObjectURL(editData.image_url)} style={{width: "50%", height: "50%"}} alt="Tournament Image" />
                            ) : (
                                <img src={`//www.primetime-tournaments.com/${editData.image_url}`} style={{width: "50%", height: "50%"}} alt="Tournament Image" />
                            )}<br></br>
                            <Form.Label>Team Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => handleImageChange('image_url', e.target.files[0])}
                                accept="*.png,*jpeg,*.jpg,image/*"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleEditModalClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() =>onEdit(editData)}>
                    {edittxt}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TeamEditScreen
