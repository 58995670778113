import React from 'react';
// import styles from './TreeComponent2.css';

const BracketTable = () => {

  return (
    <div className="bracket">
                <svg width="2000" height="1600" xmlns="http://www.w3.org/2000/svg">
            <text x="20" y="30" font-size="16" font-weight="bold">Winner's Bracket</text>
        
            <rect x="150" y="60" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="160" y="80" font-size="12">Team 1</text>
            <rect x="150" y="140" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="160" y="160" font-size="12">Team 2</text>
            <line x1="270" y1="75" x2="300" y2="75" stroke="black" />
            <line x1="270" y1="155" x2="300" y2="155" stroke="black" />
            <line x1="300" y1="75" x2="300" y2="155" stroke="black" />
        
            <rect x="150" y="220" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="160" y="240" font-size="12">Team 3</text>
            <rect x="150" y="300" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="160" y="320" font-size="12">Team 4</text>
            <line x1="270" y1="235" x2="300" y2="235" stroke="black" />
            <line x1="270" y1="315" x2="300" y2="315" stroke="black" />
            <line x1="300" y1="235" x2="300" y2="315" stroke="black" />
        
            <rect x="150" y="380" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="160" y="400" font-size="12">Team 5</text>
            <rect x="150" y="460" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="160" y="480" font-size="12">Team 6</text>
            <line x1="270" y1="395" x2="300" y2="395" stroke="black" />
            <line x1="270" y1="475" x2="300" y2="475" stroke="black" />
            <line x1="300" y1="395" x2="300" y2="475" stroke="black" />
        
            <rect x="150" y="540" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="160" y="560" font-size="12">Team 7</text>
            <rect x="150" y="620" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="160" y="640" font-size="12">Team 8</text>
            <line x1="270" y1="555" x2="300" y2="555" stroke="black" />
            <line x1="270" y1="635" x2="300" y2="635" stroke="black" />
            <line x1="300" y1="555" x2="300" y2="635" stroke="black" />
            {/* end of round w3 */}
        
            <rect x="300" y="95" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="310" y="115" font-size="12">Team 1</text>
            <rect x="300" y="260" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="310" y="280" font-size="12">Team 2</text>
            <line x1="420" y1="110" x2="450" y2="110" stroke="black" />
            <line x1="420" y1="275" x2="450" y2="275" stroke="black" />
            <line x1="450" y1="110" x2="450" y2="275" stroke="black" />
        
            <rect x="300" y="415" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="310" y="435" font-size="12">Team 3</text>
            <rect x="300" y="580" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="310" y="600" font-size="12">Team 4</text>
            <line x1="420" y1="430" x2="450" y2="430" stroke="black" />
            <line x1="420" y1="595" x2="450" y2="595" stroke="black" />
            <line x1="450" y1="430" x2="450" y2="595" stroke="black" />
            {/* end of round w4 */}
        
            <rect x="450" y="165" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="460" y="185" font-size="12">Team 1</text>
            <rect x="450" y="495" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="460" y="515" font-size="12">Team 2</text>
            <line x1="570" y1="170" x2="750" y2="170" stroke="black" />
            <line x1="570" y1="510" x2="750" y2="510" stroke="black" />
            <line x1="750" y1="170" x2="750" y2="510" stroke="black" />
            {/* end of round w5 */}
        
            <rect x="750" y="340" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="770" y="360" font-size="12">Team 1</text>
        
            <text x="200" y="680" font-size="16" font-weight="bold">Loser's Bracket</text>
        
            <rect x="300" y="730" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="310" y="750" font-size="12">Loser 1</text>
            <rect x="300" y="830" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="310" y="850" font-size="12">Loser 2</text>
            <line x1="420" y1="745" x2="450" y2="745" stroke="black" />
            <line x1="420" y1="845" x2="450" y2="845" stroke="black" />
            <line x1="450" y1="745" x2="450" y2="845" stroke="black" />
        
            <rect x="300" y="930" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="310" y="950" font-size="12">Loser 3</text>
            <rect x="300" y="1030" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="310" y="1050" font-size="12">Loser 4</text>
            <line x1="420" y1="945" x2="450" y2="945" stroke="black" />
            <line x1="420" y1="1045" x2="450" y2="1045" stroke="black" />
            <line x1="450" y1="945" x2="450" y2="1045" stroke="black" />
            {/* end of round l4 */}
            <rect x="450" y="700" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="460" y="720" font-size="12">Loser 1</text>
            <rect x="450" y="775" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="460" y="795" font-size="12">Loser 2</text>
            <line x1="570" y1="715" x2="600" y2="715" stroke="black" />
            <line x1="570" y1="790" x2="600" y2="790" stroke="black" />
            <line x1="600" y1="715" x2="600" y2="790" stroke="black" />
        
            <rect x="450" y="975" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="460" y="995" font-size="12">Loser 3</text>
            <rect x="450" y="1060" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="460" y="1080" font-size="12">Loser 4</text>
            <line x1="570" y1="990" x2="600" y2="990" stroke="black" />
            <line x1="570" y1="1075" x2="600" y2="1075" stroke="black" />
            <line x1="600" y1="990" x2="600" y2="1075" stroke="black" />
            {/* end of round l5 */}
        
            <rect x="600" y="730" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="620" y="750" font-size="12">Loser 1</text>
            <rect x="600" y="1010" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="620" y="1030" font-size="12">Loser 2</text>
            <line x1="720" y1="740" x2="750" y2="740" stroke="black" />
            <line x1="720" y1="1025" x2="750" y2="1025" stroke="black" />
            <line x1="750" y1="740" x2="750" y2="1025" stroke="black" />
            {/* end of round l6 */}
        
            <rect x="750" y="685" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="770" y="705" font-size="12">Loser 1</text>
            <rect x="750" y="820" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="770" y="840" font-size="12">Loser 2</text>
            <line x1="870" y1="700" x2="900" y2="700" stroke="black" />
            <line x1="870" y1="835" x2="900" y2="835" stroke="black" />
            <line x1="900" y1="700" x2="900" y2="835" stroke="black" />
            {/* end of round l7 */}
        
            <rect x="900" y="750" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="920" y="770" font-size="12">Loser 1</text>
            {/* end of round l8 */}
        
            <line x1="870" y1="355" x2="1100" y2="355" stroke="black" />
            <line x1="1020" y1="765" x2="1100" y2="765" stroke="black" />
            <line x1="1100" y1="355" x2="1100" y2="765" stroke="black" />
            
            <rect x="1100" y="570" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1120" y="590" font-size="12">winner 1</text>
            <rect x="1100" y="850" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1120" y="870" font-size="12">loser 2</text>
            <line x1="1220" y1="585" x2="1250" y2="585" stroke="black" />
            <line x1="1220" y1="870" x2="1250" y2="870" stroke="black" />
            <line x1="1250" y1="585" x2="1250" y2="870" stroke="black" />
            {/* end of round l9 */}
        
            <rect x="1250" y="700" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1270" y="720" font-size="12">winner 1</text>
            {/* end of round l10 */}
        </svg>
    </div>
  );
};

export default BracketTable;