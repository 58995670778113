import React from "react";
import { Navigate } from "react-router-dom";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";
import { GetTokenLocalStorage } from "../../services/Localstorage";


export default function PublicRoute({ children }) {

    const token = GetTokenLocalStorage()


    return (
        token ? <Navigate to={WEB_ROUTES.DASHBOARD} replace /> : children

    );
}
