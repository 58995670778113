import React from 'react';
// import styles from './TreeComponent2.css';

const BracketTable = () => {

  return (
    <div className="bracket">
          <svg width="2000" height="1600" xmlns="http://www.w3.org/2000/svg">
            <text x="20" y="30" font-size="16" font-weight="bold">Winner's Bracket</text>
        
            <rect x="70" y="160" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="80" y="180" font-size="12">Team 3</text>
            <rect x="70" y="240" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="80" y="260" font-size="12">Team 4</text>
            <line x1="190" y1="175" x2="220" y2="175" stroke="black" />
            <line x1="190" y1="255" x2="220" y2="255" stroke="black" />
            <line x1="220" y1="175" x2="220" y2="255" stroke="black" />
        
            <rect x="70" y="320" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="80" y="340" font-size="12">Team 5</text>
            <rect x="70" y="400" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="80" y="420" font-size="12">Team 6</text>
            <line x1="190" y1="335" x2="220" y2="335" stroke="black" />
            <line x1="190" y1="415" x2="220" y2="415" stroke="black" />
            <line x1="220" y1="335" x2="220" y2="415" stroke="black" />
        
            <rect x="70" y="480" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="80" y="500" font-size="12">Team 7</text>
            <rect x="70" y="560" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="80" y="580" font-size="12">Team 8</text>
            <line x1="190" y1="495" x2="220" y2="495" stroke="black" />
            <line x1="190" y1="575" x2="220" y2="575" stroke="black" />
            <line x1="220" y1="495" x2="220" y2="575" stroke="black" />
            {/* end of round w3 */}
        
            <rect x="220" y="35" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="230" y="55" font-size="12">Team 1</text>
            <rect x="220" y="200" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="230" y="220" font-size="12">Team 2</text>
            <line x1="340" y1="50" x2="370" y2="50" stroke="black" />
            <line x1="340" y1="215" x2="370" y2="215" stroke="black" />
            <line x1="370" y1="50" x2="370" y2="215" stroke="black" />
        
            <rect x="220" y="355" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="230" y="375" font-size="12">Team 3</text>
            <rect x="220" y="520" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="230" y="540" font-size="12">Team 4</text>
            <line x1="340" y1="370" x2="370" y2="370" stroke="black" />
            <line x1="340" y1="535" x2="370" y2="535" stroke="black" />
            <line x1="370" y1="370" x2="370" y2="535" stroke="black" />
            {/* end of round w4 */}
        
            <rect x="370" y="105" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="380" y="125" font-size="12">Team 1</text>
            <rect x="370" y="435" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="380" y="455" font-size="12">Team 2</text>
            <line x1="490" y1="110" x2="670" y2="110" stroke="black" />
            <line x1="490" y1="450" x2="670" y2="450" stroke="black" />
            <line x1="670" y1="110" x2="670" y2="450" stroke="black" />
            {/* end of round w5 */}
        
            <rect x="670" y="280" width="120" height="30" fill="lightblue" stroke="black" />
            <text x="690" y="300" font-size="12">Team 1</text>
        
            <text x="210" y="620" font-size="16" font-weight="bold">Loser's Bracket</text>
        
            <rect x="220" y="670" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="230" y="690" font-size="12">Loser 1</text>
            <rect x="220" y="770" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="230" y="790" font-size="12">Loser 2</text>
            <line x1="340" y1="685" x2="370" y2="685" stroke="black" />
            <line x1="340" y1="785" x2="370" y2="785" stroke="black" />
            <line x1="370" y1="685" x2="370" y2="785" stroke="black" />
            {/* end of round l4 */}
        
            <rect x="370" y="640" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="380" y="660" font-size="12">Loser 1</text>
            <rect x="370" y="715" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="380" y="735" font-size="12">Loser 2</text>
            <line x1="490" y1="655" x2="520" y2="655" stroke="black" />
            <line x1="490" y1="730" x2="520" y2="730" stroke="black" />
            <line x1="520" y1="655" x2="520" y2="730" stroke="black" />
        
            <rect x="370" y="915" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="380" y="935" font-size="12">Loser 3</text>
            <rect x="370" y="990" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="380" y="1010" font-size="12">Loser 4</text>
            <line x1="490" y1="930" x2="520" y2="930" stroke="black" />
            <line x1="490" y1="1005" x2="520" y2="1005" stroke="black" />
            <line x1="520" y1="930" x2="520" y2="1005" stroke="black" />
            {/* end of round l5 */}
        
            <rect x="520" y="670" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="540" y="690" font-size="12">Loser 1</text>
            <rect x="520" y="950" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="540" y="970" font-size="12">Loser 2</text>
            <line x1="640" y1="680" x2="670" y2="680" stroke="black" />
            <line x1="640" y1="965" x2="670" y2="965" stroke="black" />
            <line x1="670" y1="680" x2="670" y2="965" stroke="black" />
            {/* end of round l6 */}
        
            <rect x="670" y="625" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="690" y="645" font-size="12">Loser 1</text>
            <rect x="670" y="760" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="690" y="780" font-size="12">Loser 2</text>
            <line x1="790" y1="640" x2="820" y2="640" stroke="black" />
            <line x1="790" y1="775" x2="820" y2="775" stroke="black" />
            <line x1="820" y1="640" x2="820" y2="775" stroke="black" />
            {/* end of round l7 */}
        
            <rect x="820" y="690" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="840" y="710" font-size="12">Loser 1</text>
            {/* end of round l8 */}
        
            <line x1="790" y1="295" x2="1020" y2="295" stroke="black" />
            <line x1="940" y1="705" x2="1020" y2="705" stroke="black" />
            <line x1="1020" y1="295" x2="1020" y2="705" stroke="black" />
            
            <rect x="1020" y="510" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1040" y="530" font-size="12">winner 1</text>
            <rect x="1020" y="790" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1040" y="810" font-size="12">loser 2</text>
            <line x1="1140" y1="525" x2="1170" y2="525" stroke="black" />
            <line x1="1140" y1="810" x2="1170" y2="810" stroke="black" />
            <line x1="1170" y1="525" x2="1170" y2="810" stroke="black" />
            {/* end of round l9 */}
        
            <rect x="1170" y="640" width="120" height="30" fill="lightyellow" stroke="black" />
            <text x="1190" y="660" font-size="12">winner 1</text>
            {/* end of round l10 */}
        </svg>
    </div>
  );
};

export default BracketTable;