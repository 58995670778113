import React, { useEffect, useState } from "react";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/notification.module.css";
import { FaCircleXmark } from "react-icons/fa6";
import { Col, Row, Button } from "react-bootstrap";
import { Input, Select } from "../CustomComponents";
import { ageGroupList, genderList, sportsTypeList } from "../../constants";
// import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";

const EditPlayerModal = ({ handleEditModalClose, data, onEdit }) => {
  const [editData, setEditData] = useState({
    slug: data.slug ?? "",
    name: data.name ?? "",
    gender: data.gender ?? "",
    sports_type: data.sports_type ?? "",
    dateOfBirth: data.dateOfBirth ?? "",
    group_type: data.group_type ?? "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState();

  const handleInputChange = (name, value) => {
    if (isSubmitted) return;
    setEditData(prev => {
        return {
            ...prev,
            [name]: value
        }
    })
    setError(prev => {
        return {
            ...prev,
            [name]: !value ? `${name} should not be empty` : ""
        }
    })
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0"> {data ? "Edit Player" : "Add Player"} </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Row>
              <Col md={12}>
                <p className="m-0">
                  Player Name
                </p>
                <Input
                  classes="mt-2 mb-3"
                  type="text"
                  name="name"
                  placeholder="Enter user name"
                  value={editData.name}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md={6}>
                <p className="m-0">
                  Date of Birth
                </p>
                <Input
                  classes="mt-2 mb-3"
                  type="date"
                  name="dateOfBirth"
                  value={editData.dateOfBirth}
                  onChange={handleInputChange}
                />
              </Col>
              <Col md={6}>
                <p className="m-0">
                  Gender
                </p>
                <Select
                  classes="mt-2 mb-3"
                  options={genderList}
                  name="gender"
                  placeholder="Select Gender"
                  value={editData.gender}
                  onChange={handleInputChange} 
                />
              </Col>
              <Col md={6}>
                <p className="m-0">
                  Age Group
                </p>
                <Select
                  classes="mt-2 mb-3"
                  options={ageGroupList}
                  name="group_type"
                  placeholder="Select Age Group"
                  value={editData.group_type}
                  onChange={handleInputChange} 
                />
              </Col>
              <Col md={6}>
                <p className="m-0">
                  Sports Type
                </p>
                <Select
                  classes="mt-2 mb-3"
                  options={sportsTypeList}
                  name="sports_type"
                  placeholder="Select Sports Type"
                  value={editData.sports_type}
                  onChange={handleInputChange} 
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button variant="primary" onClick={() =>onEdit(editData)}>
                  {data ? "Update" : "Add"}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditPlayerModal;
