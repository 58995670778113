import React, { useState } from "react";
import cssStyles from "./styles/notification.module.css";
import { FaCircleXmark } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
import { refundTeam } from "../../services/generalService";
import Toast from "../../Hooks/useToast";
import { Col, Row, Modal, Button, Form } from "react-bootstrap";
import _, { set } from "lodash";

const EditTournamentModal = ({show, handleEditModalClose, onEdit, data, edittxt }) => {

    const formatDateForInput = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        const cstDate = new Date(date.getTime() - 6 * 60 * 60 * 1000); // Subtract 6 hours for CST
        return cstDate.toISOString().slice(0, 16);
    };

    const [editData, setEditData] = useState({
        name: data.name ?? "",
        location: data.location ?? "",
        ticket_price: data.ticket_price ?? "",
        registration_fee: data.registration_fee ?? "",
        state: data.state ?? "",
        zip_code: data.zip_code ?? "",
        season: data.season ?? "",
        class: data.class ?? "",
        sports_type: data.sports_type ?? "",
        description: data.description ?? "",
        age_groups: data.age_groups ?? "",
        rules: data.rules ?? "",
        media_url: data.media_url ?? "",
        thumbnail_url: data.thumbnail_url ?? "",
    })

    const sports = [
        { name: "Baseball"},
        { name: "Fast Pitch"},
    ];

    const classes = [
        { name: "A"},
        { name: "B"},
        { name: "C"},
        { name: "REC"},
        { name: "Other"},
    ];

    const [startDate, setStartDate] = useState(formatDateForInput(data.start_date || ''));
    const [endDate, setEndDate] = useState(formatDateForInput(data.end_date|| ''));
    const [registrationStartDate, setRegistrationStartDate] = useState(formatDateForInput(data.registration_start_date || ''));
    const [registrationEndDate, setRegistrationEndDate] = useState(formatDateForInput(data.registration_end_date || ''));
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState();

    const handleInputChange = (name, value) => {
        if (isSubmitted) return;
        setEditData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        setError(prev => {
            return {
                ...prev,
                [name]: !value ? `${name} should not be empty` : ""
            }
        })
    }

    const handleImageChange = (name, value) => {
        if (isSubmitted) return;
        setEditData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    return (
        <Modal size="xl" show={show} onHide={handleEditModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Tournament</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={8} sm={12}>
                        <Row>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Tournament Name</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={editData.name}
                                    onChange={(e) => handleInputChange('name',e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Sports Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={editData.sports_type}
                                    onChange={(e) => handleInputChange('sports_type', e.target.value)}
                                >
                                    <option value="">Select Sport</option>
                                    {sports.map((sport) => (
                                        <option key={sport.name} value={sport.name}>
                                            {sport.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-3'>
                                <Form.Label>Ticket Price</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={editData.ticket_price}
                                    onChange={(e) => handleInputChange('ticket_price', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-3'>
                                <Form.Label>Registration Fee</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={editData.registration_fee}
                                    onChange={(e) => handleInputChange('registration_fee', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-3'>
                                <Form.Label>Season</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={editData.season}
                                    onChange={(e) => handleInputChange('season', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-3'>
                                <Form.Label>Class</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={editData.class}
                                    onChange={(e) => handleInputChange('class', e.target.value)}
                                >
                                    <option value="">Select Class</option>
                                    {classes.map((classItem) => (
                                        <option key={classItem.name} value={classItem.name}>
                                            {classItem.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-sm-12'>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={editData.location}
                                    onChange={(e) => handleInputChange('location',e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-4'>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={editData.state}
                                    onChange={(e) => handleInputChange('state',e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-4'>
                                <Form.Label>Zip Code</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={editData.zip_code}
                                    onChange={(e) => handleInputChange('zip_code',e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Registration Start Date</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="registrationStartDate"
                                    value={registrationStartDate}
                                    onChange={(e) => setRegistrationStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Registration End Date</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    value={registrationEndDate}
                                    onChange={(e) => setRegistrationEndDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="startDate"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12 col-md-6'>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="startDate"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='col-sm-12'>
                                <Form.Label>Description</Form.Label>
                                <textarea
                                    className="form-control"
                                    onChange={(e) => handleInputChange('description', e.target.value)}
                                >{editData.description}</textarea>
                            </Form.Group>
                            <Form.Group className='col-sm-12'>
                                <Form.Label>Rules</Form.Label>
                                <textarea
                                    className="form-control"
                                    onChange={(e) => handleInputChange('rules', e.target.value)}
                                >{editData.rules}</textarea>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group className='col-sm-12 mb-1'>
                            {editData.media_url && typeof editData.media_url === 'object' ? (
                                <img src={URL.createObjectURL(editData.media_url)} style={{width: "50%", height: "50%"}} alt="Tournament Image" />
                            ) : (
                                <img src={`//www.primetime-tournaments.com/${editData.image_url}`} style={{width: "50%", height: "50%"}} alt="Tournament Image" />
                            )}<br></br>
                            <Form.Label>Tournament Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => handleImageChange('media_url', e.target.files[0])}
                                accept="*.png,*jpeg,*.jpg,image/*"
                            />
                        </Form.Group>
                        <Form.Group className='col-sm-12'>
                            {editData.thumbnail_url && typeof editData.thumbnail_url === 'object' ? (
                                <img src={URL.createObjectURL(editData.thumbnail_url)} style={{width: "50%", height: "50%"}}  alt="Tournament Thumbnail" />
                            ) : (
                                <img src={`//www.primetime-tournaments.com/${editData.thumbnail_url}`} style={{width: "50%", height: "50%"}} alt="Tournament Thumbnail" />
                            )}
                            <br></br>
                            <Form.Label>Thumbnail</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => handleImageChange('thumbnail_url', e.target.files[0])}
                                accept="*.png,*jpeg,*.jpg,image/*"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleEditModalClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() =>onEdit(editData, startDate, endDate, registrationStartDate, registrationEndDate)}>
                    {edittxt}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditTournamentModal;
