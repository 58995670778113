import React, { useState, useEffect } from 'react';
import Three from '../../../Components/Brackets/ThreeTeamBracket';
import Four from '../../../Components/Brackets/FourTeamBracket';
import Five from '../../../Components/Brackets/FiveTeamBracket';
import Six from '../../../Components/Brackets/SixTeamBracket';
import Seven from '../../../Components/Brackets/SevenTeamBracket';
import Eight from '../../../Components/Brackets/EightTeamBracket';
import Nine from '../../../Components/Brackets/NineTeamBracket';
import Ten from '../../../Components/Brackets/TenTeamBracket';
import Eleven from '../../../Components/Brackets/ElevenTeamBracket';
import Twelve from '../../../Components/Brackets/TwelveTeamBracket';
import Thirteen from '../../../Components/Brackets/ThirteenTeamBracket';
import Fourteen from '../../../Components/Brackets/FourteenTeamBracket';
import Fifteen from '../../../Components/Brackets/FifteenTeamBracket';
import Sixteen from '../../../Components/Brackets/SixteenTeamBracket';
import Seventeen from '../../../Components/Brackets/SeventeenTeamBracket';
import Eighteen from '../../../Components/Brackets/EighteenTeamBracket';
import Nineteen from '../../../Components/Brackets/NineteenTeamBracket';
import Twenty from '../../../Components/Brackets/TwentyTeamBracket';
import { getFixtureBySlug } from '../../../services/generalService';

const DynamicBracket = ({ ageGroupID }) => {
  const [bracket, setBracket] = useState(null);

  useEffect(() => {
    const fetchFixture = async () => {
      const res = await getFixtureBySlug(ageGroupID);
      setBracket(res?.data?.data);
    };
    fetchFixture();
  }, [ageGroupID]);

  const renderBracket = () => {
    switch (bracket) {
      case 'three':
        return <Three />;
      case 'four':
        return <Four />;
      case 'five':
        return <Five />;
      case 'six':
        return <Six />;
      case 'seven':
        return <Seven />;
      case 'eight':
        return <Eight />;
      case 'nine':
        return <Nine />;
      case 'ten':
        return <Ten />;
      case 'eleven':
        return <Eleven />;
      case 'twelve':
        return <Twelve />;
      case 'thirteen':
        return <Thirteen />;
      case 'fourteen':
        return <Fourteen />;
      case 'fifteen':
        return <Fifteen />;
      case 'sixteen':
        return <Sixteen />;
      case 'seventeen':
        return <Seventeen />;
      case 'eighteen':
        return <Eighteen />;
      case 'nineteen':
        return <Nineteen />;
      case 'twenty':
        return <Twenty />;
      default:
        return <div>No bracket available</div>;
    }
  };

  return (
    <div>
      {renderBracket()}
    </div>
  );
};

export default DynamicBracket;