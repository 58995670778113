import React, { useEffect, useState, useCallback } from "react";
import styles from "./styles/user_management.module.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import { Row, Col, Stack } from "react-bootstrap";
import Table from "../../Components/Table/Table";
import { MdDeleteForever } from "react-icons/md";
import debounce from "lodash.debounce";
import Toast from "../../Hooks/useToast";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditCoachModal from "../../Components/Modals/EditCoachModal";
import { LiaBanSolid } from "react-icons/lia";
import {
  banCoach,
  getCoachList,
  removeCoach,
  unBanCoach,
} from "../../services/coach";
import { FaBackward, FaForward } from "react-icons/fa6";
import { IoCaretBackSharp, IoCaretForwardSharp } from "react-icons/io5";
import ApiLoader from "../TournamentManagement/Components/SpinnerLoader";

const CoachManagementScreen = ({}) => {
  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [paginationDetail, setPaginationDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const GetCoachList = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const params = { page: page, limit: 10, search: search.trim() || "" };
      const result = await getCoachList(params);
      setData(result?.data?.data);
      setPaginationDetail(result?.data?.links);
      setIndicationMessage("");
    } catch (e) {
      Toast(e?.response?.message || "Error loading Coaches", "error");
    } finally {
      setLoading(false);
    }
  }, [page, search]);

  const debouncedGetCoaches = useCallback(
    debounce(() => {
      GetCoachList();
    }, 1000),
    [GetCoachList]
  );

  useEffect(() => {
    debouncedGetCoaches();
    return debouncedGetCoaches.cancel;
  }, [search, debouncedGetCoaches]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setRowData("");
  };

  const deleteData = async () => {
    try {
      const result = await removeCoach(rowData?.slug);
      GetCoachList();
      setDeleteModal(false);
      Toast(result?.data?.message, "success");
    } catch (e) {
      Toast(e?.response?.data?.message, "error");
    }
  };

  function indication() {
    return indicationMessage;
  }

  const handleBannedonClick = async (data) => {
    setLoading(true);
    let result;
    try {
      if (data?.is_banned) {
        result = await unBanCoach(data?.slug);
      } else {
        result = await banCoach(data?.slug);
      }
      GetCoachList();
    } catch (e) {
      Toast(e?.response?.data?.message);
    } finally {
      setLoading(false); // Ensure loading is set to false in both success and failure cases
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center">
            <img
              src={`//www.primetime-tournaments.com/${row?.image_url}`}
              style={{
                height: "40px",
                maxHeight: "50px",
                borderRadius: "50%",
                margin: "10px",
                padding: "5px",
              }}
            />
            <span>{row?.name || "-"}</span>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "300px",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row?.slug) {
          return (
            <>
              <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2 "
                    style={{
                      width: "100px",
                    }}
                    role="button"
                    onClick={() => {
                      setRowData(row);
                      handleDeleteModalOpen();
                    }}
                  >
                    <span
                      className={`d-flex align-items-center justify-content-center pe-1`}
                    >
                      <MdDeleteForever />
                    </span>
                    <span>Remove</span>
                  </div>
                </div>
                <div
                  className={`${
                    loading
                      ? styles.btnBgdiabled // Disabled style when loading
                      : row?.is_banned
                      ? styles.btnBgunban // Style for unban
                      : styles.btnBgban // Style for ban
                  } d-flex justify-content-center align-items-center rounded-3 mx-2 p-2`}
                  style={{
                    width: "100px",
                  }}
                  role="button"
                  onClick={() => {
                    if (!loading) {
                      // Prevent onClick when loading
                      handleBannedonClick(row);
                    }
                  }}
                >
                  <span className="align-items-center d-flex justify-content-center pe-1">
                    <LiaBanSolid />
                  </span>
                  <span>
                    {loading ? <ApiLoader /> : row?.is_banned ? "UNBAN" : "BAN"}
                  </span>
                </div>
              </div>
            </>
          );
        }
      },
    },
  ];

  const handleBackPagination = () => {
    if (paginationDetail?.current > 1) {
      setPage((prev) => prev - 1);
    }
  };
  
  const handleForwardPagination = () => {
    if (paginationDetail?.current < paginationDetail?.total) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={rowData}
          onDelete={deleteData}
          deletetxt={"Remove"}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div className={`${styles.titleContainer} mb-1`}>
          <SubTitle text={"Coach Management"} classes="text-dark"/>
        </div>
      </div>
      <div className={styles.headerContainer}>
        <Row>
          <Col>
            <Stack direction="horizontal" gap={3}>
                <input
                  type="text"
                  placeholder="Search"
                  className={`${styles.searchInput} form-control`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
            </Stack>
          </Col>
        </Row>
      </div>
      {loading ? (
        <ApiLoader />
      ) :(
          <div className={styles.mainTable}>
            <Table data={data} columns={columns} indication={indication} />
            <div
              className="pagination"
              style={{
                fontSize: "15px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginRight: "12px",
              }}
            >
              <FaBackward
                cursor={"pointer"}
                color={paginationDetail?.current === 1 ? "gray" : "blue"}
                size={20}
                onClick={() => setPage(1)}
              />
              <IoCaretBackSharp
                cursor={"pointer"}
                color={paginationDetail?.current === 1 ? "gray" : "blue"}
                size={20}
                onClick={handleBackPagination}
              />
              <span className="text-dark">
                {paginationDetail?.current}/{paginationDetail?.total}
              </span>
              <IoCaretForwardSharp
                cursor={"pointer"}
                color={
                  paginationDetail?.current === paginationDetail?.total
                    ? "gray"
                    : "blue"
                }
                size={20}
                onClick={handleForwardPagination}
              />
              <FaForward
                cursor={"pointer"}
                color={
                  paginationDetail?.current === paginationDetail?.total
                    ? "gray"
                    : "blue"
                }
                size={20}
                onClick={() => setPage(paginationDetail?.total)}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default CoachManagementScreen;
