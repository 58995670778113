import React from 'react';

import styles from './styles/EditProfile.module.scss'
import { Button, Description, Input, SubTitle } from '../../Components/CustomComponents';


export default function EditProfile() {
    // const profileAPI = ProfileAPI()
    // const dispatch = useDispatch();
    // const { user } = useSelector(state => state.default);
    // const [isSubmitted, setIsSubmitted] = useState(false);
    // const [data, setData] = useState({
    //     firstname: user.firstname ?? "",
    //     lastname: user.lastname ?? "",
    //     image_url: user.image_url ?? ""
    // })

    // const [error, setError] = useState({
    //     firstname: "",
    //     lastname: "",
    // })

    // const handleInputChange = (name, value) => {
    //     if (isSubmitted) return;
    //     setData(prev => {
    //         return {
    //             ...prev,
    //             [name]: value
    //         }
    //     })
    //     setError(prev => {
    //         return {
    //             ...prev,
    //             [name]: !value ? `${name} should not be empty` : ""
    //         }
    //     })
    // }
    // const handleImageChange = (e) => {
    //     if (isSubmitted) return;
    //     setData(prev => {
    //         return {
    //             ...prev,
    //             image_url: e.target.files[0]
    //         }
    //     })
    // }
    // const handleSubmit = async () => {
    //     if (!data.firstname || !data.lastname || isSubmitted) return;

    //     try {
    //         setIsSubmitted(true);
    //         const formData = new FormData();
    //         formData.append('firstname', data.firstname)
    //         formData.append('lastname', data.lastname)
    //         if (_.isObject(data.image_url)) {
    //             formData.append('image_url', data.image_url)
    //         }

    //         const response = await profileAPI.update(formData);
    //         dispatch(setUser(response))
    //         Toast('Profile updated successfully', 'success', false)
    //     }
    //     catch (err) {
    //         Toast(err.message, 'error', false)
    //     }
    //     finally {
    //         setIsSubmitted(false)
    //     }
    // }
    return (
        <div className={styles.EditProfile}>
            <SubTitle
                text='Edit Profile'
            />
            <Description
                text='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
                classes='fs-8 mb-4'
            />
            <div className={styles.form}>
                <img
                    src='/images/profile.png'
                    alt='User Profile'
                    className={styles.userImage}
                />
                <SubTitle
                    text='Abram Westervelt'
                    classes='fs-5 mt-2'
                />
                <Description
                    text='phillip.curtis@example.com'
                    classes='fs-8 my-1'
                />
                <Description
                    text='+84 905070017'
                    classes='fs-8 mb-3'
                />
                <Input
                    placeholder='First name'
                />
                <Input
                    placeholder='Last name'
                />
                <Input
                    placeholder='Email Address'
                />

                <Button
                    text='Save and Continue'
                    classes='mt-5 mb-2 fs-6'
                />

            </div>
        </div>
    )
}