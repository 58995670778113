import React, { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import cssStyles from "./index.module.css";
import Tabs from "../../Components/Tabs/Tabs";
import DetailsContent from "./DetailsContent";
import TeamsContent from "./TeamsContent";
import TablesContent from "./TablesContent";
import RulesContent from "./RulesContent";
import { ROUTES } from "./Route";
import { endMatch, getTournamentBySlug, startMatch } from "../../services/generalService";
import Toast from "../../Hooks/useToast";
import { deleteTournament } from "../../services/TournamentService";
import { editTournament } from "../../services/TournamentService";
import CustomDeleteModal from "../../Components/Modals/NewDeleteCustomModal";
import EditTournamentModal from "../../Components/Modals/EditTournamentModal";



const TournamentDetailsScreen = ({ slug, setPage}) => {
    const [key, setKey] = useState("details");
    const [data, setData] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [selectedData, setSelectedData] = useState([])
    const array = [
        {value: "details", label: "Details"},
        // { value: "tables", label: "Brackets"},
        { value: "teams", label: "Teams"},
        { value: "rules", label: "Rules"},
    ];

    useEffect(() => {
        GetTournamentBySlug()
    }, [slug])

    const GetTournamentBySlug = async () => {
        const response = await getTournamentBySlug(slug)
        setData(response?.data?.data)
    }

    const tabContent = () => {
        switch (key) {
            case "details":
                return <DetailsContent data={data} />;
            case "tables":
                return <TablesContent data={data} />;
            case "teams":
                return <TeamsContent data={data} />;
            case "rules":
                return <RulesContent data={data} />;

            default:
                return <DetailsContent data={data} />;
        }
    };

    const handleBackButtonClck = () => {
        setPage(ROUTES.TOURNAMENT)
    };

    const DeleteTournament = async (data) => {
        try {
            const response = await deleteTournament(data?.slug)
            Toast(response?.data?.message, "success");
            // GetScheduleTournament()
            setPage(ROUTES.TOURNAMENT)
        } catch (error) {
            Toast(error?.response?.data?.message, "error");
        }
    }

    const EditTournament = async (data, startDate, endDate, registrationStartDate, registrationEndDate) => {
        if (data !== null || data !== undefined) {
            data['start_date'] = startDate;
            data['end_date'] = endDate;
            data['registration_start_date'] = registrationStartDate;
            data['registration_end_date'] = registrationEndDate;

            const res = await editTournament(slug, data);

            if (res.status === 200) {
                Toast(res?.data?.message, "success");
                setData(res.data.data);
                setEditModal(false);
            } else {
                console.log('Error editing Tournament');
            }
        }
    }

    const handleDeleteModalClose = () => {
        setDeleteModal(false)
    }
    
    const handleSelectedData = (data, modal) => {
        if (modal === 'delete') {
            setDeleteModal(true)
        }
        else {
            setEditModal(true)
        }
        setSelectedData(data)
    }

    const handleEditModalClose = () => {
        setEditModal(false)
    }

    return (
        <>
            <div className={cssStyles.ViewTournament}>
                <div className="mb-2">
                    <Stack direction="horizontal" gap={3}>
                        <button onClick={handleBackButtonClck} className={`${cssStyles.backButton} mt-2`} >Back</button>
                        <button onClick={() => handleSelectedData(data, 'edit')} className={`${cssStyles.editBadge} mt-2`} >Edit</button>
                        <button onClick={() => handleSelectedData(data, 'delete')} className={`${cssStyles.deleteBadge} mt-2`} >DELETE</button>
                    </Stack>
                </div>
                {/* Tournament View */}
                <div className="row mb-2">
                    <div className="col-6 text-start">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <h3>{data.name}</h3>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <img src={`//www.primetime-tournaments.com/${data.media_url}`} alt={data.name} style={{ maxWidth: '100%', height: '250px', objectFit: 'contain', borderRadius: '4px' }}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={cssStyles.beforeTabs}>
                            <Tabs tabs={array} current={key} onChange={setKey} />
                        </div>
                    </div>
                </div>
                <div style={{ color: "black" }}>{tabContent()}</div>
                    
            </div>
            {deleteModal ? (
                <CustomDeleteModal
                    handleDeleteModalClose={handleDeleteModalClose}
                    onDelete={DeleteTournament}
                    data={selectedData}
                    deletetxt={"Delete"}
                />
            ) : (
                ""
            )}
            {editModal ? (
                <EditTournamentModal
                    show={editModal}
                    handleEditModalClose={handleEditModalClose}
                    onEdit={EditTournament}
                    data={selectedData}
                    edittxt={"Save Changes"}
                />
            ) : (
                ""
            )}
        </>
    )
}

export default TournamentDetailsScreen
