import React, { useState } from "react";
import TeamSelection from "./Components/TeamSelection";
import Tabs from "../../Components/Tabs/Tabs";
import { Col, Row } from 'react-bootstrap';


const MagicContent = () => {
    const [key, setKey] = useState("6u");

    const array = [
        {
            value: "6u",
            label: "6U",
        },
        {
            value: "8u",
            label: "8U",
        },
        {
            value: "10u",
            label: "10U",
        },
        {
            value: "12u",
            label: "12U",
        },
        {
            value: "14u",
            label: "14U",
        },
        {
            value: "16u",
            label: "16U",
        },
        {
            value: "18u",
            label: "18U",
        },
        {
            value: "20u",
            label: "20U",
        },
        {
            value: "22u",
            label: "22U",
        },
        {
            value: "24u",
            label: "24U",
        },
    ];

    const inlineItemStyles = {
        background: '#222232',
        margin: '5px',
        padding: '10px 20px',
    };

    const tabContent = () => {
        switch (key) {
            case "6u":
                return <TeamSelection length='6' />;
            case "8u":
                return <TeamSelection length='8' />;
            case "10u":
                return <TeamSelection length='10' />;
            case "12u":
                return <TeamSelection length='12' />;
            case "14u":
                return <TeamSelection length='14' />;
            case "16u":
                return <TeamSelection length='16' />;
            case "18u":
                return <TeamSelection length='18' />;
            case "20u":
                return <TeamSelection length='20' />;
            case "22u":
                return <TeamSelection length='22' />;
            case "24u":
                return <TeamSelection length='24' />;

            default:
                return <TeamSelection length='10' />;

        }
    };

    return (
        <div className="mt-3">
            <h2 className="fw-5 text-white">Magic Box</h2>

            <p className="text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>

            <p className="text-white m-0 fw-bold mt-5">
                Team Age Group
            </p>

            <Row>
                <Col className='p-2'>

                    <Tabs tabs={array} current={key} onChange={setKey} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
                </Col>
            </Row>

            {
                tabContent()
            }

        </div>
    )
}

export default MagicContent
