import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import cssStyles from "./index.module.css";
import Table from "../../Components/Table/Table";
import Toast from "../../Hooks/useToast";
import { ROUTES } from "./Route.js";
import { Button, SubTitle, } from "../../Components/CustomComponents/index.js";
import { FaEye, FaBackward, FaForward, FaRegEdit } from "react-icons/fa";
import { IoCaretForwardSharp, IoCaretBackSharp } from "react-icons/io5";
import { Col, Row, Stack } from "react-bootstrap";
import { getScheduleTournament } from '../../services/generalService';
import moment from "moment";


export default function TournamentManagementScreen({ setPage, setSlug }) {
  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [loader, setLoader] = useState(false);
  const [paginationDetail, setPaginationDetail] = useState(null);
  const [teamPage, setTeamPage] = useState(1);
  const [search, setSearch] = useState("");

  const GetTournaments = useCallback(async () => {
    if (loader) return;
    setLoader(true);
    try {
      const params = { page: teamPage, limit: 10, search: search.trim() || "" };
      const result = await getScheduleTournament(params);
      setData(result?.data?.data);
      setPaginationDetail(result?.data?.links);
      setIndicationMessage("");
    } catch (e) {
      Toast(e?.response?.message || "Error loading teams", "error");
    } finally {
      setLoader(false);
    }
  }, [teamPage, search]);

  const debouncedGetTournaments = useCallback(
    debounce(() => {
      GetTournaments();
    }, 1000),
    [GetTournaments]
  );
  
  useEffect(() => {
    debouncedGetTournaments();
    return debouncedGetTournaments.cancel;
  }, [search, debouncedGetTournaments]);

  function indication() {
    return indicationMessage;
  }

  const handleViewTournament = (row) => {
    setSlug(row.slug);
    setPage(ROUTES.TOURNAMENT_DETAILS);
  };

  const gotoCreateTournamentScreen = () => {
    setPage(ROUTES.CREATE_TOURNAMENT);
  }
  
  const handleBackPagination = () => {
    if (paginationDetail?.current > 1) {
      setTeamPage((prev) => prev - 1);
    }
  };
  
  const handleForwardPagination = () => {
    if (paginationDetail?.current < paginationDetail?.total) {
      setTeamPage((prev) => prev + 1);
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: { width: "200px" },
    },
    {
      dataField: "location",
      text: "Location",
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.location && (
          <div className="d-flex align-items-center">
            <span>{row.location}</span>
          </div>
        )
      ),
    },
    {
      dataField: "sports_type",
      text: "Sports Type",
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.sports_type && (
          <div className="d-flex align-items-center">
            <span>{row.sports_type}</span>
          </div>
        )
      ),
    },
    {
      dataField: "registration_start_date",
      text: "Registration Start Date",
      sort: true,
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.registration_start_date && (
          <div className="d-flex align-items-center">
            <span>{moment(row.registration_start_date).format("MMMM DD, YYYY")}</span>
          </div>
        )
      ),
    },
    {
      dataField: "registration_end_date",
      text: "Registration End Date",
      sort: true,
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.registration_end_date && (
          <div className="d-flex align-items-center">
            <span>{moment(row.registration_end_date).format("MMMM DD, YYYY")}</span>
          </div>
        )
      ),
    },
    {
      dataField: "start_date",
      text: "Start Date",
      sort: true,
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.start_date && (
          <div className="d-flex align-items-center">
            <span>{moment(row.start_date).format("MMMM DD, YYYY")}</span>
          </div>
        )
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "200px" },
      formatter: (cellContent, row) => (
        row.slug && (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className={`${cssStyles.btnBg} d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2`}
              role="button"
              onClick={() => handleViewTournament(row)}
            >
              <span className="d-flex align-items-center pe-1">
                <FaEye />
              </span>
              <span>View</span>
            </div>
          </div>
        )
      ),
    },
  ];

  return (
    <div className={cssStyles.tournament}>
      <div className={cssStyles.headerContainer}>
        <div>
          <SubTitle text={"Tournament"} classes="text-dark"/>
        </div>
        <Row>
          <Col md={12}>
            <Button
              classes={`${cssStyles.cardBtn} `}
              text="Create Tournament"
              onClick={gotoCreateTournamentScreen}
            ></Button>
          </Col>
        </Row>
      </div>
      <div className={cssStyles.searchContainer}>
        <Row>
          <Col>
            <Stack direction="horizontal" gap={3}>
              <input
                type="text"
                value={search}
                placeholder="Search for Teams..."
                className="form-control"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Stack>
          </Col>
        </Row>
      </div>
      <br></br>
      <div className={cssStyles.headerContainer}>
        <div className={cssStyles.mainTable}>
          <Table data={data} columns={columns} indication={indication} />
          <div className="pagination" style={{ fontSize: "15px", color: "black", display: "flex", justifyContent: "end", alignItems: "center", marginRight: "12px" }}>
            <FaBackward
              cursor="pointer"
              color={paginationDetail?.current === 1 ? "gray" : "blue"}
              size={20}
              onClick={() => setTeamPage(1)}
            />
            <IoCaretBackSharp
              cursor="pointer"
              color={paginationDetail?.current === 1 ? "gray" : "blue"}
              size={20}
              onClick={handleBackPagination}
            />
            <span>{paginationDetail?.current}/{paginationDetail?.total}</span>
            <IoCaretForwardSharp
              cursor="pointer"
              color={paginationDetail?.current === paginationDetail?.total ? "gray" : "blue"}
              size={20}
              onClick={handleForwardPagination}
            />
            <FaForward
              cursor="pointer"
              color={paginationDetail?.current === paginationDetail?.total ? "gray" : "blue"}
              size={20}
              onClick={() => setTeamPage(paginationDetail?.total)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}